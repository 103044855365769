import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import { useHistory } from "react-router-dom";
import * as userService from "services/userService";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Divider from "@material-ui/core/Divider";

import "../style/notification.css";

const useStyles = makeStyles({
  root: {
    paddingTop: "20px",
    textAlign: "center"
  }, 
});


const NotificationPopup = (props) => {
  const history = useHistory();

  const [totalCount, setTotalCount] = useState(0);
  const listItems = props.listItems;
 
  const goNotification = (notify) => {
    history.push("/notification");

    console.log(notify)
  };

  useEffect(() => {
    let count = 0;    

    setTotalCount(count);    
  }, []);

  return (
    <div className={"notification"} style={{ position: "relative" }}>
      {/* <div className={"iconSection"}>
        <img
          alt={"Notification"}
          src={require("./notificationIcon.svg")}
          onClick={() => this.toggleNotification()}
          style={{ cursor: "pointer" }}
        ></img>
        <span className={"iconBadge"}>{totalCount}</span>
      </div> */}
        <div
          style={{
            position: "absolute",
            width: "200px",
            border: "0.5px solid #8080803d",
            minHeight: "100px",
            overflowY: "auto",
            top: "30px"
          }}
          className={"notificationBar"}
        >
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: "14px", textAlign: "left", width: "93%" }}>
              お知らせ
            </div>
            <CloseIcon style={{ width: "10%", cursor: "pointer" }} onClick={() => props.onFinishScreen()}/>
          </div>
          <Divider />
          <br />

          { listItems.map((item, index) => {
            return (
              <div
                style={{ background: "#fff", padding: "5px", cursor: "pointer" }}
                className={"lineItmes"}
                onClick={() => goNotification(item)}
                key = {index}
              >
                <div>
                  <span
                    style={{ fontSize: "13px", fontWeight: 700 }}
                  >{`${item.type}`}</span>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 700,
                      color: "#747474",
                      float: "right"
                    }}
                  >{item.timestamp}
                  </span>
                </div>
                <div style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{__html: item.content}} />
              </div>
            );
          })}
          <a href="/notification" style={{ fontSize: "14px", textAlign: "right", margin: 0, color: "#42A5F5" }}>
            すべてのお知らせを見る
          </a>
        </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
