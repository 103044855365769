import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "views/components/style/mainStyle";

import "../style/privacy.css";

const useStyles = makeStyles(styles);

export default function ContactForms() {
    const classes = useStyles();
    return (
        <div className={ classes.privacyContainer }>
            <h2>プライバシーポリシー</h2>
            <h5>令和3年12月1日</h5><br />
            <p>株式会社アルファ・アンド・カンパニー（以下「当社」といいます）は、「アルファ・パートナー」及び関連プログラム・事業をご利用になる方（以下「利用者」といいます）のプライバシーを尊重し、利用者の個人情報の適正な取扱いを確保すべく、以下のとおりプライバシーポリシー（以下「本プライバシーポリシー」といいます）を定めます。</p>
            <h3>1. 個人情報</h3>
            <p>本プライバシーポリシーにおいて、個人情報とは、個人の氏名、生年月日、年齢、性別、住所、電話番号、家族構成、趣味、嗜好、電子メールアドレス、ID、IPアドレスおよびタイムスタンプ、勤務先、所属、勤務先住所、勤務先電話番号、クレジットカード番号、銀行口座番号、訪れたホームページの情報、苦情、ご相談またはお問い合せの情報等であって、これらのうちの1つあるいは2つ以上を組み合せることによって、特定の個人を識別できるものを指します。</p>
            <h3>2. 個人情報の利用目的</h3>
            <p>当社は、取得した個人情報を以下の目的のために利用させていただきます。<br /><br />
            (1)パートナーマッチングおよび関連する情報提供等の当社サービスを提供するため<br />
            (2)当社の有料サービスを利用している利用者に利用料金を請求するため<br />
            (3)利用者への連絡、商品・サービスの案内、プレゼントの発送等のため<br />
            (4)利用者の年齢、職業、性別、趣味・嗜好等に合わせて当社サービスのページ上の情報、サービスおよび広告配信をカスタマイズするため<br />
            (5)当社サービスの改善や新サービスの開発等に役立てるため<br />
            (6)当社サービスの利用規約に違反する態様でのご利用を防止するため<br />
            (7)当社サービスに関して、個人を識別できない形式に加工した統計データを作成するため<br />
            </p>
            <h3>3. 利用目的による制限</h3>
            <p>当社は、取得した個人情報について、利用目的の達成に必要な範囲内で取り扱います。個人情報を利用目的の範囲外で取り扱う場合は、あらかじめ利用者ご本人の同意を得て行います。ただし、以下の場合はこの限りではありません。<br /><br />
            (1)法令に基づく場合<br />
            (2)人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき<br />
            (3)公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき<br />
            (4)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            </p>
            <h3>4. 情報の取得</h3>
            <p>• 当社は、個人情報の入手を適法かつ公正な手段によって行い、利用者の意思に反する不正な方法により入手いたしません。<br />
            • 当社は、個人情報を間接的に入手する場合には、入手する個人情報について、提供者が本人から適正に入手したものであるかどうかを確認します。
            </p>
            <h3>5. 取得に際しての利用目的の通知等</h3>
            <p>当社は、個人情報を取得する際は、その利用目的をあらかじめ通知または公表します。利用目的が変更された場合も利用者に通知または公表します。当社は、利用目的の達成に必要な範囲内で、適正に個人情報を利用します。</p>
            <h3>6. 個人情報の適切な保護</h3>
            <p>当社は、利用者の個人情報を、正確、最新のものとするよう適切な処置を講じます。また、利用者の個人情報について、漏えい、滅失またはき損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。安全管理措置の内容についてのお問合せは下記17.お問い合わせ先までお申し出ください。</p>
            <h3>7. 従業者・委託先の監督</h3>
            <p>当社は、個人情報を取り扱う従業者に対して、必要かつ適切な監督をおこないます。当社は、取得した個人情報の取扱いを委託する場合があります。その場合には、個人情報の預託に係る基本契約等の必要な契約を締結し、委託を受けた者に対する必要かつ適切な監督をおこないます。</p>
            <h3>8. 個人情報の第三者への提供</h3>
            <p>当社は、利用者の個人情報について、利用者ご本人の同意を得ずに第三者に提供することは原則としておこないません。提供先・提供内容を特定した上で、利用者の同意を得た場合に限り、提供します。ただし、以下の場合はこの限りではありません。<br /><br />
            (1)当社の業務委託先が、当社に代わってダイレクトメール、電子メールその他手段で情報または役務を提供する場合<br />
            (2)当社の業務委託先が、当社に代わってアフターサービスなどの個別の役務を提供するために必要がある場合<br />
            (3)統計的情報を提供する目的で、個々の個人情報を集積または分析し、個人を識別できない形式に加工して、その統計データを開示する場合<br />
            (4)有料サービスを利用している利用者に利用料金を請求する目的で、当社の業務委託先である決済システム会社、クレジット会社および銀行（秘密保持契約を締結）に有料サービスを利用している利用者の個人情報を預託する場合<br />
            (5)プレゼントの発送等のために配送業者に利用者の個人情報を提供する場合<br />
            (6)上記のほか利用目的の達成に必要な範囲内において外部委託した場合<br />
            (7)法令により提供が許容されている場合<br />
            (8)個人情報の共同利用にあたり、別途「共同して利用する者」として当社が定める者に対して提供する場合
            </p>
            <h3>9. 個人情報の開示</h3>
            <p>当社は、利用者ご本人より当該本人の個人情報の開示を求められた場合には、遅滞なく応じます。ただし、以下の場合は、その全部または一部を開示しない場合があります。<br /><br />
            (1)利用者本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
            (2)当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
            (3)他の法令に違反することとなる場合
            </p>
            <h3>10. 個人情報の訂正、利用停止等の手続</h3>
            <p>当社は、利用者ご本人より当該本人の個人情報の利用目的の通知、訂正、追加、削除、利用の停止または消去、第三者への提供の停止を求められた場合には、利用者ご本人であることを確認させていただいた上で合理的な期間内に対応いたします。</p>
            <h3>11. 免責</h3>
            <p>以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。<br />
            (1)利用者ご本人が当社サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合<br />
            (2)利用者が当社サービス上に入力した情報等により、個人が識別できてしまった場合
            </p>
            <h3>12. 統計データの利用</h3>
            <p>当社は、取得した利用者の個人情報をもとに、個人を識別できないように加工した統計データを作成することがあります。
            個人を識別できない統計データについては、当社は何ら制限なく利用することができるものとします。
            </p>
            <h3>13. クッキーの使用等について</h3>
            <p>• 当社は、利用者が、当社や当社の提携先等のサービスをご利用になったり、ページをご覧になったりする際に、利用者のIPアドレス、クッキー情報や利用者がご覧になった広告・ページ、利用者のご利用環境などの情報を利用者のブラウザから自動的に受け取り、サーバーに記録します。<br />
            • 当社は、新しいサービスを検討したり、提供しているサービスや広告の内容をより利用者の皆様に適したものとするために、性別、職業、年齢、ご覧になったページ・広告、ご利用時間帯、ご利用の方法、ご利用環境等の利用者に関する情報を利用する場合があります。<br />
            • 当社は、広告主や情報提供元、サービス提供元などに、どのような広告や情報、サービスなどを掲載または提供していただくことが効果的であるかを分析して提供するために、利用者に関する情報を分析したり、分析のためのアンケートの対象の抽出を行う場合があります。なお、個人が識別できるような情報が分析結果に含まれることはありません。<br />
            • 当社は、当社のサイトに掲載される広告の一部の表示および配信を第三者企業に委託する場合があります。これらの第三者企業がクッキーを設定している場合には、情報は第三者企業のサーバーに記録され、第三者企業のプライバシーポリシーのもとで管理されます。
            </p>
            <h3>14. プライバシーポリシーの改定について</h3>
            <p>当社は、プライバシーポリシーの全部または一部を改定することがあります。具体的な変更手続きについては法令の定めに従い、重要な変更がある場合には、当社サイト上でお知らせします。</p>
            <h3>15. 関係法令・ガイドライン等の遵守</h3>
            <p>当社は、個人情報の保護に関する法律その他の法令及び個人情報保護委員会のガイドライン等を遵守します。</p>
            <h3>16. 当社の名称・住所・代表者の氏名</h3>
            <p>株式会社アルファ・アンド・カンパニー<br />
            〒107-0061　東京都港区北青山3-6-7-11F<br />
            代表取締役社長　 入住壽彦
            </p>
            <h3>17. お問い合わせ先</h3>
            <p>個人情報の管理についての責任者は、αAcademy事務局長とします。個人情報に関するお問い合わせや苦情、または開示、訂正、削除若しくは利用停止の申し出等については、下記を問い合わせ窓口とします。<br />
            <br /><br />
            α Academy事務局<br />
            E-Mail: admin@alpha-academy.com
            </p>
        </div>
    );
}