import { connect } from "react-redux";
import {
  Router,
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import * as authAction from "redux/actions/auth";
import * as storageService from "../../../services/storageService";
import * as authService from "../../../services/authService";
import { httpService } from "services/httpService.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button1 from "components/CustomButtons/Button1.js";
import logo from "assets/img/alpha_advisors_logo.jpg";
import AvatarImage from "assets/img/faces/noname.jpg";
import Button from "components/CustomButtons/Button.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MailIcon from "@material-ui/icons/Mail";
import PeopleIcon from "@material-ui/icons/People";

import Slide from "@material-ui/core/Slide";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  MenuItem,
  ListItemText,
  ListItem,
  Divider,
  Avatar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "../style/mainStyle.js";
import List from "@material-ui/core/List";
import MsgBadge from "./MsgBadge";
import Badge from "@material-ui/core/Badge";
import NotificationPopup from "./NotificationPopup";
import moment from "moment";

import SearchProfile from "views/Pages/SearchProfile";
import { blackColor } from "assets/jss/material-dashboard-pro-react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Brightness5Icon from "@material-ui/icons/Brightness5";
import Menu from "@material-ui/core/Menu";
import { convTime } from 'utils/utils';
import config from "config/config";

const useDrawerStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
});

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const headersData = [
  {
    label: "Listings",
    href: "/listings",
  },
  {
    label: "Mentors",
    href: "/mentors",
  },
  {
    label: "My Account",
    href: "/account",
  },
  {
    label: "Log Out",
    href: "/logout",
  },
];

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const TopHeader = (props) => {
  const { isAuthenticated } = props;
  const history = useHistory();
  // const [showNotification, setShowNotification] = useState(false);
  const [listNotification, setListNotification] = useState([]);
  const [numNotification, setNumNotification] = useState(0);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  
  useEffect(() => {
    let cleanup = false;

    if (isAuthenticated)
      refreshNotifications(cleanup);

    if (isAuthenticated) {
      setInterval(() => {
          refreshNotifications(cleanup);
      }, 10000);
    }

    return () => (cleanup = true);
  }, [numNotification]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    // refreshNotifications();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const [logoutModal, setLogoutModal] = React.useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
    history.push("/");
  };
  const classes = useStyles();

  const handleLogOut = () => {
    authService.logOut().then((result) => {});

    setLogoutModal(false);
    storageService.removeStorage("token");
    storageService.removeStorage("user");
    storageService.removeStorage("skip");
    history.push("/");
    props.logout();
  };

  const user = storageService.getStorage("user");
  let user_id = 0;
  if (user !== null) {
    user_id = user.id;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMouseClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleNotification = () => {
    if (listNotification.length > 0) {
      httpService
      .post("api/notification/markRead", 
      {ts: moment(listNotification[0].ts).format("YYYY-MM-DD HH:mm:ss")})
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      }); 
    }
    // setShowNotification(!showNotification);
  };

  const refreshNotifications = (cleanup) => {
    httpService
    .get("api/notification/getUnread")
    .then((res) => {
      if (res.data.message == "success") {
        const listItems = [];

        res.data.items.map((item, index) => {
          const notify = {
            ts: item.created_at,
            timestamp: convTime(item.created_at),
            type: item.type,
            content: item.content
          }
          listItems.push(notify);
        })
        if (!cleanup) setNumNotification(res.data.items.length);

        setListNotification(listItems);
      } else {
      }
    })
    .catch((err) => {
      console.log(err);
    });

    // return () => (cleanup = true);
  };

  const clearAllMessage = () => {
    // listItems = [];
  };

  const profileImg = props.user.user_avatar
  ? config.baseUrl + "avatars/" + props.user.user_avatar
  : AvatarImage;

  const goSettingPage = (item) => {
    history.push("/setting/" + item);
    handleClose();
  };

  const goLogOut = () => {
    setLogoutModal(true);
    handleClose();
  }

  const displayDesktop = () => {
    return (
      <>
      <Toolbar className={classes.toolbar}>
        <div className={classes.alignLeft}>
          <a href="/">
            <img
              src={logo}
              alt="..."
              width="100%"
              style={{ cursor: "pointer" }}
              onClick={scrollToTop}
            />
          </a>
        </div>
        <div>
          <ul style={{ marginTop: "19px" }}>
            <li className={classes.navitem}>
              <a href="/">
                <span style={{ color: "black", fontSize: "18px" }}>
                  <b>🔍</b>
                </span>
              </a>
            </li>
            <li className={classes.navitem}>
              {!isAuthenticated && (
                <>
                <span style={{ color: "black", fontSize: "18px" }}>
                  <b>✉</b>
                </span>
                {/* <MailIcon color="action" /> */}
                </>
              )}
              {isAuthenticated && <MsgBadge />}
            </li>
            {isAuthenticated && (
              <li className={classes.navitem}>
                <a href="/match">
                  {/* <span style={{ color: "black", fontSize: "18px" }}>
                    <b>リスト</b>
                  </span> */}
                  <Badge
                    color="secondary"
                    badgeContent={numNotification}
                    max={99}
                    style={{ marginTop: -2, cursor: "pointer" }}
                  >
                    <span style={{ color: "black", fontSize: "18px" }}>
                      <b>👥</b>
                    </span>
                    {/* <PeopleIcon color="action" /> */}
                  </Badge>                  
                </a>
              </li>
              )
            }
            { window.innerWidth > 1100 &&
            <li className={classes.navitem}>
              <a href="/notification">
                {!isAuthenticated ?
                  <>
                  <span style={{ color: "black", fontSize: "18px" }}>
                    <b>🔔</b>
                  </span>
                  {/* <NotificationsIcon color="action" /> */}
                  </>
                :
                  <Badge
                    color="secondary"
                    badgeContent={numNotification}
                    max={99}
                    style={{ marginTop: -2, cursor: "pointer" }}
                    onClick={() => toggleNotification()}
                  >
                    <span style={{ color: "black", fontSize: "18px" }}>
                      <b>🔔</b>
                    </span>
                    {/* <NotificationsIcon color="action"/> */}
                  </Badge>
                }
              </a>
            </li>
            }
          </ul>
        </div>
        <div
          className={classes.alignRight}
        >
          { isAuthenticated &&
            <div style={{ marginTop: "12px", color: blackColor }}>
              <span style={{ marginLeft: "5px", cursor: "pointer" }}
                onClick={handleMouseClick}
              >
                <Brightness5Icon color="inherit" />
              </span>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                <MenuItem onClick={() => goSettingPage(2)}>設 定</MenuItem>
                <MenuItem onClick={() => goSettingPage(3)}>フィードバック・要望</MenuItem>
                <MenuItem onClick={() => goLogOut()}>ログアウト</MenuItem>
              </Menu>
            </div>
          }
          {!isAuthenticated &&
          <>
            <a
              href="/auth/login"
              style={{ color: "#e12e3e" }}
              rel={"noindex nofollow"}
            >
              <Button1
                style={{
                  backgroundColor: "white",
                  color: "#e12e3e",
                  border: "1px solid #e12e3e",
                  marginLeft: "20px",
                }}
              >
                ログイン
              </Button1>
            </a>
            <a
              href="/auth/register"
              style={{ color: "white" }}
              rel={"noindex nofollow"}
            >
              <Button1
                style={{
                  backgroundColor: "#e12e3e",
                  border: "1px solid #e12e3e",
                }}
              >
                新規登録
              </Button1>
            </a>
          </>}
          {isAuthenticated && (
            <a href={"/Profile/" + user_id}>
              <Avatar
                variant="rounded"
                src={profileImg}
                alt="avatar"
                style={{marginTop: "4px"}}
                // onClick={() => goSettingPage(1)}
              />
            </a>
          )}
        </div>

        <Dialog
          open={logoutModal}
          TransitionComponent={Transition}
          onClose={() => setLogoutModal(false)}
        >
          <DialogContent>
            <DialogTitle disableTypography style={{ textAlign: "center" }}>
              <h4>
                <b>ログアウトしますか？</b>
              </h4>
            </DialogTitle>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => setLogoutModal(false)}
                style={{ backgroundColor: "lightgrey", color: "black" }}
              >
                キャンセル
              </Button>
              <Button
                onClick={() => handleLogOut()}
                style={{ backgroundColor: "#e12e3e" }}
              >
                ログアウト
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Toolbar>
      {/* {showNotification && <NotificationPopup listItems={listNotification}
        onFinishScreen={toggleNotification} onClearAll={clearAllMessage}/>} */}
      </>
    );
  };

  const classesMy = useDrawerStyles();

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "red",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classesMy.root}>
            <IconButton onClick={handleDrawerClose}>
              {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div className={classes.drawerContainer}>
            <div className={classes.sidebar}>
              <a href="/">
                <div style={{ color: "black", fontSize: "18px" }}>
                  <b>🔍</b>
                </div>
              </a>
              <br />
              {!isAuthenticated && (
                <div style={{ color: "black", fontSize: "18px" }}>
                  <b>✉</b>
                </div>
              )}

              {isAuthenticated && <MsgBadge />}
              
              <br />

              {isAuthenticated && (
                <a href="/match">
                  <div style={{ color: "black", fontSize: "18px", marginTop: 20 }}>
                    <b>👥</b>
                  </div>
                </a>
                )
              }
              <br />

              <a href="/notification">
                <div style={{ color: "black", fontSize: "18px" }}>
                  <b>🔔</b>
                </div>
              </a>
              <br />
            </div>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>

        <div style={{ display: "flex"}}>
          {!isAuthenticated &&
          <>
            <a
              href="/auth/login"
              style={{ color: "#e12e3e" }}
              rel={"noindex nofollow"}
            >
              <Button1
                style={{
                  backgroundColor: "white",
                  color: "#e12e3e",
                  border: "1px solid #e12e3e",
                  marginLeft: "20px",
                }}
              >
                ログイン
              </Button1>
            </a>
            <a
              href="/auth/register"
              style={{ color: "white" }}
              rel={"noindex nofollow"}
            >
              <Button1
                style={{
                  backgroundColor: "#e12e3e",
                  border: "1px solid #e12e3e",
                }}
              >
                新規登録
              </Button1>
            </a>
          </>}
          {isAuthenticated && (
            <span style={{cursor: "pointer"}}>
              <Avatar
                variant="rounded"
                src={profileImg}
                alt="avatar"
                style={{marginTop: "4px"}}
                onClick={() => goSettingPage(1)}
              />
            </span>            
          )}
          { isAuthenticated &&
            <div style={{ marginTop: "12px", color: blackColor }}>
              <span style={{ marginLeft: "5px", cursor: "pointer" }}
                onClick={handleMouseClick}
              >
                <Brightness5Icon color="inherit" />
              </span>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                PaperProps={{
                  style: {
                    width: 200,
                  },
                }}
              >
                <MenuItem onClick={() => goSettingPage(2)}>設 定</MenuItem>
                <MenuItem onClick={() => goSettingPage(3)}>フィードバック・要望</MenuItem>
                <MenuItem onClick={() => goLogOut()}>ログアウト</MenuItem>
              </Menu>
            </div>
          }
        </div>
        <Dialog
          open={logoutModal}
          TransitionComponent={Transition}
          onClose={() => setLogoutModal(false)}
        >
          <DialogContent>
            <DialogTitle disableTypography style={{ textAlign: "center" }}>
              <h4>
                <b>ログアウトしますか？</b>
              </h4>
            </DialogTitle>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => setLogoutModal(false)}
                style={{ backgroundColor: "lightgrey", color: "black" }}
              >
                キャンセル
              </Button>
              <Button
                onClick={() => handleLogOut()}
                style={{ backgroundColor: "#e12e3e" }}
              >
                ログアウト
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Toolbar>
    );
  };

  const femmecubatorLogo = (
    <img
      src={logo}
      alt="..."
      width="60%"
      height="60%"
      style={{ cursor: "pointer" }}
      onClick={scrollToTop}
    />
  );

  return (
    <div className={classes.topbar}>
      <AppBar className={classes.topbar}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {
  logout: authAction.Logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopHeader));
