import { httpService } from "./httpService";

export const getUsers = () => {
  return httpService.get("api/admin/users/");
};

export const getNicedUsers = () => {
  return httpService.get("api/admin/user/nices");
};

// export const getNicedUsers = (id) => {
//   return httpService.get("api/admin/user/niced/" + id);
// };

export const getNicedUserChat = ( from, to ) => {
  return httpService.post("api/admin/user/chat", { from, to });
};

export const getBusinesspeople = () => {
  return httpService.get("api/admin/businesses");
};

// export const getUserById = ({ id }) => {
//   return httpService.get(`users/:${id}`);
// };

export const updateUserStatus = (id, status) => {
  return httpService.post("api/admin/user/status", { id, status });
};

export const updateUserPw = (id, pw) => {
  return httpService.post("api/admin/user/password", { id, pw });
};

export const deleteUser = (id) => {
  return httpService.deleteDetail("api/admin/user/delete/" + id);
};

export const updateUser = ({ id, company, name, phone, email }) => {
  return httpService.put("api/profile/update", {
    id,
    company,
    name,
    phone,
    email,
  });
};

export const getAllProfiles = () => {
  return httpService.get(`api/profiles`);
};

export const getProfile = (id) => {
  return httpService.get(`api/profile/${id}`);
};

export const getAttribute = () => {
  return httpService.get(`api/attributes`);
};
export const getLanguage = () => {
  return httpService.get(`api/languages`);
};
export const getCountries = () => {
  return httpService.get(`api/countries`);
};
export const getStates = (countryId) => {
  return httpService.get(`api/states/${countryId}`);
};
export const getCities = (stateId) => {
  return httpService.get(`api/cities/${stateId}`);
};
export const getSourceIncome = () => {
  return httpService.get(`api/sourceIncomes`);
};
export const getLanguageAll = () => {
  return httpService.get(`api/languages/all`);
};
export const getIncome = () => {
  return httpService.get(`api/incomes`);
};
export const getHobby = () => {
  return httpService.get(`api/hobbies`);
};
export const getHobbyAll = () => {
  return httpService.get(`api/hobbies/all`);
};
export const getQuestion = () => {
  return httpService.get(`api/questions`);
};

export const paramStore = (selectItem, newParam) => {
  return httpService.post(`api/master/store`, {selectItem, newParam});
}
export const paramUpdate = (selectItem, param, newParam) => {
  return httpService.post(`api/master/update`, {selectItem, param, newParam});
}
export const paramDelete = (selectItem, param) => {
  return httpService.post(`api/master/delete`, {selectItem, param});
}

export const getUsersApproave = (id) => {
  return httpService.get(`api/profile/approave/${id}`);
};

export const setUsersApproave = (approaveData) => {
  return httpService.post(`api/profile/set_approave`, approaveData);
};

export const setVisitDel = (from_id, to_id) => {
  return httpService.post(`api/profile/visitDel`, { from_id, to_id });
};

export const setReject = (to_id, from_id) => {
  return httpService.post(`api/profile/reject`, { to_id, from_id });
};

export const setAccept = (to_id, from_id) => {
  return httpService.post(`api/profile/accept`, { to_id, from_id });
};

export const updateProfile = (profileData) => {
  return httpService.upload("api/profile/update", profileData);
};

export const getSearchProfiles = (val) => {
  return httpService.post(`api/profiles/search`, val);
};

export const updateUserCompany = ({ id, company, name, phone, email }) => {
  return httpService.put("api/profile/company", {
    id,
    company,
    name,
    phone,
    email,
  });
};

export const updateUserMail = ({ id, company, name, phone, email }) => {
  return httpService.put("api/profile/mail", {
    id,
    company,
    name,
    phone,
    email,
  });
};

export const updateUserPasswd = ( id, passwd ) => {
  return httpService.put("api/profile/passwd", { id, passwd });
};

export const createUser = ({ company, name, phone, email, password, role }) => {
  //   console.log(login, password);
  return httpService.post("api/admin/user/create", {
    name,
    company,
    phone,
    email,
    password,
    role,
  });
};

export const sendLike = (id) => {
  return httpService.get(`api/profile/like/${id}`);
};

export const sendUnLike = (id) => {
  return httpService.get(`api/profile/unlike/${id}`);
};

export const getMyLiked = (id) => {
  return httpService.get(`api/profile/liked_num`);
};

export const getChatList = (id) => {
  return httpService.get(`api/chat/list/${id}`);
};

export const searchChatUser = (name) => {
  return httpService.post(`api/chat/search`, name);
};

export const getNumUnreadMsg = (id) => {
  return httpService.get(`api/chat/unread_num/${id}`);
};
