import React, { Fragment, useEffect, useState } from "react";
import { TextField, Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";

import * as userService from "services/userService";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./style/mainStyle";

const useStyles = makeStyles(styles);

function AdminMaster(props) {
  const [attributes, setAttributes] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [hobbies, setHobbies] = useState([]);

  const [selectItem, setSelectItem] = useState("");
  const [items, setItems] = useState([]);
  const [param, setParam] = useState("");
  const [newParam, setNewParam] = useState("");

  // const [dropSelect, setDropSelect] = useState({
  //   attribute: "",
  //   income: "",
  //   language: "",
  //   hobby: "",
  // });

  useEffect(() => {
    userService.getAttribute().then((result) => {
      setAttributes(result.data.items);
    });
    userService.getLanguageAll().then((result) => {
      setLanguages(result.data.items);
    });
    userService.getIncome().then((result) => {
      setIncomes(result.data.items);
    });
    userService.getHobbyAll().then((result) => {
      setHobbies(result.data.items);
    });
  }, []);

  // const handleChange = (name) => (event) => {
  //   setDropSelect({ ...dropSelect, [name]: event.target.value });
  // };

  const handleChangeParam = (event) => {
    setParam(event.target.value);
  };

  const handleChangeNewParam = (event) => {
    setNewParam(event.target.value);
  };

  const handleChangeSelect = (event) => {
    setSelectItem(event.target.value);
    switch (event.target.value) {
      case "attributes":
        setItems(attributes);
        break;
      case "incomes":
        setItems(incomes);
        break;
      case "languages":
        setItems(languages);
        break;
      case "hobbies":
        setItems(hobbies);
        break;
    }
  };

  const classes = useStyles();

  function resultView(selectItem, result) {
    switch (selectItem) {
      case "attributes":
        setAttributes(result.data.items);
        break;
      case "incomes":
        setIncomes(result.data.items);
        break;
      case "languages":
        setLanguages(result.data.items);
        break;
      case "hobbies":
        setHobbies(result.data.items);
        break;
    }
    setItems(result.data.items);
  }

  function onStore() {
    userService.paramStore(selectItem, newParam).then((result) => {
      resultView(selectItem, result);
    });
  }
  function onUpdate() {
    userService.paramUpdate(selectItem, param, newParam).then((result) => {
      resultView(selectItem, result);
    });
  }
  function onDelete() {
    userService.paramDelete(selectItem, param).then((result) => {
      resultView(selectItem, result);
    });
  }

  return (
    <Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Paper>
            <Grid container justifyContent="center">
              <Grid item xs={5} style={{margin: 10}}>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                  オプション
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectItem}
                    onChange={handleChangeSelect}
                    label="オプション"
                  >
                    <MenuItem value={"attributes"}>属性（選択式）</MenuItem>
                    <MenuItem value={"incomes"}>年収</MenuItem>
                    <MenuItem value={"languages"}>使用言語</MenuItem>
                    <MenuItem value={"hobbies"}>興味・関心</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                  オプション値
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={param}
                    onChange={handleChangeParam}
                    label="オプション値"
                  >
                    {items && items.map((item, key) => (
                      <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField 
                  label="新しいパラメータ"  
                  onChange={handleChangeNewParam}
                  style={{width: "100%"}}
                />
                <Grid container justifyContent="center" spacing={4}>
                  <Grid item xs="auto">
                    <Button
                      size="md"
                      onClick={() => onStore()}
                      color="success"
                      style={{marginTop: 30}}
                      >
                      新規作成
                    </Button>
                  </Grid>
                  <Grid item xs="auto">
                  <Button
                    size="md"
                    onClick={() => onUpdate()}
                    color="success"
                    style={{marginTop: 30}}
                    >
                    変更
                  </Button>
                  </Grid>
                  <Grid item xs="auto">
                  <Button
                    size="md"
                    onClick={() => onDelete()}
                    color="success"
                    style={{marginTop: 30}}
                    >
                    削除
                  </Button>
                  </Grid>
                </Grid>
                {/* <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    年収
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dropSelect.income}
                    onChange={handleChange("income")}
                    label="年収"
                  >
                    {languages && languages.map((item, key) => (
                      <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    使用言語
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dropSelect.language}
                    onChange={handleChange("language")}
                    label="使用言語"
                  >
                    {languages && languages.map((item, key) => (
                      <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    属性（選択式）
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dropSelect.attribute}
                    onChange={handleChange("attribute")}
                    label="属性（選択式）"
                  >
                    {attributes && attributes.map((item, key) => (
                      <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.selectType}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    興味・関心
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={dropSelect.hobby}
                    onChange={handleChange("hobby")}
                    label="興味・関心"
                  >
                    {hobbies && hobbies.map((item, key) => (
                      <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default AdminMaster;