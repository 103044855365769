import React, { Fragment, useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";

import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Switch from "@material-ui/core/Switch";
import { withStyles, Grid } from "@material-ui/core";
import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import config from "config/config";
import AvatarImage from "assets/img/faces/noname.jpg";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import * as userService from "services/userService";

import styles from "./style/mainStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AdminChat(props) {
  const [users, setUsers] = React.useState([]);
  const [pairs, setPairs] = React.useState([]);
  const [nicedusers, setNicedUsers] = React.useState([]);
  const [deleteUserModal, setDeleteUserModal] = React.useState(false);
  const [userId, setUserId] = React.useState(0);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [nicedRowId, setNicedRowId] = useState(0);
  const [fromToData, setFromToData] = useState();
  const [toFromData, setToFromData] = useState();

  useEffect(() => {
    userService.getNicedUsers().then((res) => {
      if (res.data.message == "success" && res.data.pairs) {
        setPairs(res.data.pairs);
      } else {
        console.log("error");
      }
    });    
  }, []);

  const columns = [
    {
      title: "写真",
      field: "from_avatar",
      render: (rowData) => (
        <Avatar
          alt={`Avatar n°${rowData + 1}`}
          src={
            rowData.from_avatar
              ? config.baseUrl + "avatars/" + rowData.from_avatar
              : AvatarImage
          }
        />
      ),
    },
    { title: "名前", field: "from_name" },
    {
      title: "写真",
      field: "to_avatar",
      render: (rowData) => (
        <Avatar
          alt={`Avatar n°${rowData + 1}`}
          src={
            rowData.to_avatar
              ? config.baseUrl + "avatars/" + rowData.to_avatar
              : AvatarImage
          }
        />
      ),
    },
    { title: "名前", field: "to_name" },
    // { title: "メール", field: "email" },
  ];

  const handleRow = (row) => {

    setSelectedRow(row.tableData.id)

    userService.getNicedUserChat(row.from_id, row.to_id).then((res) => {
      if (res.data.message == "success") {
        setFromToData(res.data.from_to);
        setToFromData(res.data.to_from);
      } else {
        console.log("error");
      }
    });
  }
  
  const classes = useStyles();

  function DetailedHistory() {
    return(
      <Grid container>
        <Grid item sm={6} lg={6}>
          {fromToData && fromToData.map((item, index) => (
            <ul key={index}>
              <li>{item.time}</li>
              {item.msg}
            </ul>  
          ))}
        </Grid>
        <Grid item sm={6} lg={6}>
          {toFromData && toFromData.map((item, index) => (
            <ul key={index}>
              <li>{item.time}</li>
              {item.msg}
            </ul>  
          ))}
        </Grid>
      </Grid>
    )
  }
  
  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          lg={4}
          style={{borderRight: "1px solid black"}}
        >
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={columns}
            data={pairs}
            onRowClick={((evt, selectedRow) => {handleRow(selectedRow)}
            )}
            options={{
              actionsColumnIndex: -1,
              rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              })
            }}
            localization={{
              pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}',
                  labelRowsSelect: '人'
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected',
                  searchPlaceholder: '検索'
              },
              header: {
                  actions: '操作'
              },
              body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                      filterTooltip: 'Filter'
                  }
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={8}
        >
          <DetailedHistory />
        </Grid>
      </Grid>    
    </Fragment>
  );
}

AdminChat.propTypes = {
  theme: PropTypes.object,
  onClose: PropTypes.func,
  openTermsDialog: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default AdminChat;
