import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, InputLabel, Paper, Divider } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import PropTypes from 'prop-types';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const useStyles = makeStyles((theme) =>({
  formControl: {
    marginBottom: theme.spacing(2),
    width: "100%"
  },
  menuItem: {
    fontSize: "12px"
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  slider: {
    color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      marginTop: -10,
      backgroundColor: '#fff',
      boxShadow: iOSBoxShadow,
      '&:focus, &:hover, &.Mui-active': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -16,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },    
  }
}))

function SearchProfile(props) {
  const classes = useStyles();
  const {searchFunc} = props;

  const [searchName, setSearchName] = useState('');
  // const [languages, setLanguages] = useState([]);
  const [values, setValues] = useState({
    name: '',
    blood: '',
    language: '',
    gender:'',
    avatar:'',
    newRegister:'',
    lastLogin:'',
  })
  
  const [ageRange, setAgeRange] = React.useState([20, 45]);

  const handleChange = name => event => {
    setValues({...values,  [name]: event.target.value });
  }

  const handlechangeName = newValue => {
    setSearchName(newValue);
  }

  const handleChangeAge = (event, newValue) => {
    setAgeRange(newValue);
    console.log(ageRange);
  };

  function valueAgeRange(value) {
    return value;
  }

  // const getSelctItem = () => {
  //   let searchCon = {
  //     field: 'all',
  //     blood: values.blood,
  //     language: values.language,
  //     gender: values.gender,
  //     avatar: values.avatar,
  //     newRegister: values.newRegister,
  //     lastLogin: values.lastLogin,
  //     ageRange: ageRange
  //   }
  //   searchFunc(searchCon);
  // }

  const getSearchResult = (val) => {
    setValues({...values,  name: val });
  }

  const cancelSearch = () => {
    setValues({...values,  name: '' });
  }

  useEffect(() => {
    let searchCon = {
      name: values.name,
      blood: values.blood,
      language: values.language,
      gender: values.gender,
      avatar: values.avatar,
      newRegister: values.newRegister,
      lastLogin: values.lastLogin,
      ageRange: ageRange
    }
    searchFunc(searchCon);
  }, [values, ageRange]);

  return ( 
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <Paper className={classes.paper}>
        <p style={{ fontSize: 18, marginBottom: 10 }}><b>検 索</b></p>
          <SearchBar
            value={searchName}
            onChange={(newValue) => handlechangeName(newValue)}
            onRequestSearch={() => getSearchResult(searchName)}
            onCancelSearch={() => cancelSearch()}
            placeholder="検 索"
            className={classes.menuItem}
            style={{fontSize: 10}}
          />
        <Divider style={{marginTop: 40}} />
        <p style={{ fontSize: "18px", marginTop: "30px", marginBottom: 10 }}><b>条 件 検 索</b></p>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>性 別</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="性 別"
            value={values.gender}
            onChange={handleChange('gender')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">全 体</MenuItem>
            <MenuItem className={classes.menuItem} value="男性">男 性</MenuItem>
            <MenuItem className={classes.menuItem} value="女性">女 性</MenuItem>
            <MenuItem className={classes.menuItem} value="その他">その他</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>写 真</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="写 真"
            value={values.avatar}
            onChange={handleChange('avatar')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">気にしない</MenuItem>
            <MenuItem className={classes.menuItem} value="y">あ り</MenuItem>
            <MenuItem className={classes.menuItem} value="n">な し</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>言 語</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="言 語"
            value={values.language}
            onChange={handleChange('language')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">全 体</MenuItem>
            <MenuItem className={classes.menuItem} value="日本語">日本語 (Japanese)</MenuItem>
            <MenuItem className={classes.menuItem} value="英語">英語 (English)</MenuItem>
            <MenuItem className={classes.menuItem} value="その他">その他 (Others)</MenuItem>
           {/* {languages && languages.map((item) =>(
            <MenuItem className={classes.menuItem} value={item.id}>{item.language}</MenuItem>
           ))} */}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>血液型</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="血液型"
            value={values.blood}
            onChange={handleChange('blood')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">全 体</MenuItem>
            <MenuItem className={classes.menuItem} value="O">O</MenuItem>
            <MenuItem className={classes.menuItem} value="A">A</MenuItem>
            <MenuItem className={classes.menuItem} value="B">B</MenuItem>
            <MenuItem className={classes.menuItem} value="AB">AB</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>新規登録者</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="新規登録者"
            value={values.newRegister}
            onChange={handleChange('newRegister')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">全 員</MenuItem>
            <MenuItem className={classes.menuItem} value="day">1日以内</MenuItem>
            <MenuItem className={classes.menuItem} value="week">1週間以内</MenuItem>
            <MenuItem className={classes.menuItem} value="month">1か月以内</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl} size="small">
          <InputLabel id="demo-simple-select-outlined-label" className={classes.menuItem}>最終ログイン</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            label="最終ログイン"
            value={values.lastLogin}
            onChange={handleChange('lastLogin')}
            className={classes.menuItem}
            style={{ background: "white" }}
          >
            <MenuItem className={classes.menuItem} value="all">全 員</MenuItem>
            <MenuItem className={classes.menuItem} value="day">1日以内</MenuItem>
            <MenuItem className={classes.menuItem} value="week">1週間以内</MenuItem>
            <MenuItem className={classes.menuItem} value="month">1か月以内</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography id="range-slider" className={classes.menuItem} gutterBottom>
            年 齢
          </Typography>
          <Slider
            className={classes.slider}
            value={ageRange}
            min={15}
            max={60}
            defaultValue={30}
            onChange={handleChangeAge}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={valueAgeRange}
            style={{marginTop: 10}}
          />
        </FormControl>
        {/* <Box component="div" style={{textAlign: "center"}}>
          <Button color="primary" variant="contained" onClick={getSelctItem}>検 索</Button>
        </Box> */}
      </Paper>
    </Grid>
  );
}

SearchProfile.propTypes = {
  searchFunc: PropTypes.func,
};

export default SearchProfile