module.exports = {
  // baseUrl: 'http://localhost:8000/',
  baseUrl: 'https://api.alpha-partner.net/',
  
  // chatUrl: 'http://localhost:4000/',
  chatUrl: 'https://chat.alpha-partner.net/',
  // baseUrl: 'http://ec2-3-112-128-220.ap-northeast-1.compute.amazonaws.com:3000/',
  // chatUrl: 'http://ec2-3-112-128-220.ap-northeast-1.compute.amazonaws.com:4000/',
  // GOOGLE_CLIENT_ID: "350470497266-39dj71n0u4bb51k0h0jdeo51oc3hlaas.apps.googleusercontent.com",
  GOOGLE_CLIENT_ID: "1073499890116-j22ppt45qmu1aq3fugkboslssiv3udse.apps.googleusercontent.com",

  FACEBOOK_APP_ID: "616459406167905",
  FACEBOOK_SCOPE: "public_profile,user_friends",

  LINKEDIN_CLIENT_ID: '861qshfefe2kzm',
  LINKEDIN_CLIENT_SECRET: 'XXXXXXXX',
  // LINKEDIN_REDIRECT_URI: 'http://ec2-3-112-128-220.ap-northeast-1.compute.amazonaws.com/',
  LINKEDIN_REDIRECT_URI: 'https://alpha-partner.net/',
  // LINKEDIN_SCOPE: 'r_liteprofile%20r_emailaddress',
  LINKEDIN_SCOPE: 'r_basicprofile',
  LINKEDIN_STATE: '123456'  
}
