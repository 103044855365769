/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux";
import * as storageService from "services/storageService";
import { Signin } from "redux/actions/auth";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Home from "views/Pages/Home.js";
import Profile from "views/components/SettingProfile";
import PersonalProfile from "views/components/PersonalProfile";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import AdminUser from "views/components/AdminUser";
import Chat from "views/components/Chat";
import ContactPage from "views/Pages/ContactPage";
import PrivacyPage from "views/Pages/PrivacyPage";
import Match from "views/Pages/MatchPage";
import AdminLoginPage from "views/Pages/AdminLoginPage";
import NotificationPage from "views/Pages/NotificationPage";
import SettingPage from "views/Pages/SettingPage";

const hist = createBrowserHistory();

const token = storageService.getStorage("token");
const user = storageService.getStorage("user");
if (token) {
  store.dispatch(Signin(user));
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/dashboard" component={AdminLoginPage} />
        <Route path="/profile/:id" component={Profile} />
        <Route path="/personalProfile/:id/:type" component={Profile} />
        <Route path="/adminProfile" component={AdminUser} />
        <Route path="/chat" component={Chat} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/privacy" component={PrivacyPage} />
        <Route path="/match/:id" component={Match} />
        <Route path="/match" component={Match} />
        <Route path="/notification" component={NotificationPage} />
        <Route path="/setting/:id" component={SettingPage} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
