import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom";
// @material-ui/core components
import * as mainAction from 'redux/actions/main';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

// page components
// import ProjectList from "views/Pages/ProjectList.js";
// import ProfileUpdate from "views/Pages/ProfileUpdate.js";
// import ProposalCreate from "views/Pages/ProjectCreate.js";
// import PriceList from "views/Pages/PriceList.js";
// import UserList from "views/Pages/UserList.js";
import UserList from "views/components/AdminUser.js";
import UserChatList from "views/components/AdminChat.js";
import Master from "views/components/AdminMaster.js";
// import PsChange from "views/components/AdminPs.js";


const handleTabChange = () => {
};

const ClientDashboard = (user) => {
  const history = useHistory();

  useEffect(() => {
    if (typeof user.user.roles === 'undefined') {
      history.push("/auth/login");
    }
  }, [])
  
  return (
    user.user.roles == null ? <div /> :
    <div>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={9}>
          <NavPills
            color="rose"
            clickOnTab={handleTabChange}

            tabs={[
              // {
              //   tabButton: "案件一覧",
              //   tabContent: (
              //     <ProjectList />
              //   )
              // },
              {
                tabButton: "アカウント管理",
                tabContent: (
                  <UserList />
                )
              },
              {
                tabButton: "チャット管理",
                tabContent: (
                  <UserChatList />
                )
              },
              {
                tabButton: "マスター",
                tabContent: (
                  <Master />
                )
              },
              // {
              //   tabButton: "パスワード管理",
              //   tabContent: (
              //     <PsChange />
              //   )
              // },
              // {
              //   tabButton: "登録情報",
              //   tabContent: (
              //     <ProfileUpdate />
              //   )
              // }
            ]}
          /> 
        </GridItem>
      </GridContainer>
    </div>
  );
}


const mapStateToProps = (state, ownProps) => ({
  user: state.auth.user
})

const mapDispatchToProps = ({
  MiniActive: mainAction.MiniActive
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientDashboard))