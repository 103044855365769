import React, { Fragment, useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

// @material-ui/core components
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

// core components
import NavPills from "components/NavPills/NavPills.js";

import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";

import MoodIcon from '@material-ui/icons/Mood';

import { httpService } from "services/httpService.js";
import { convTime } from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function NotificationPage(props) {
  const history = useHistory();

  const classes = useStyles();
  const { user, isAuthenticated } = props;
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const [listNotification, setListNotification] = useState([]);

  let userId = user.id;

  const handleTabChange = () => {
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    refreshNotifications();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const refreshNotifications = () => {
    httpService
    .get("api/notification/get")
    .then((res) => {
      if (res.data.message == "success") {
        const listItems = [];

        res.data.items.map((item, index) => {
          const notify = {
            timestamp: convTime(item.created_at),
            type: item.type,
            content: item.content
          }
          listItems.push(notify);
        })
        
        setListNotification(listItems);
      } else {
      }
    })
    .catch((err) => {
      console.log(err);
    });
  };

  const goNotification = (notify) => {
    console.log(notify)
    scroll.scrollToTop();
    history.push("/match/2");
  };
  
  const NotificationPaper = () => {
    return (
    <Grid container style={{width: '90%', margin: '0 auto'}}>
      { listNotification.map((item, index) => {
        return (
        <Grid item xs={12} key={index}>
          <Paper style={{height: 80, margin: 20}}>
            <div
              style={{ background: "#fff", padding: "15px", cursor: "pointer" }}
              // className={"lineItmes"}
              onClick={() => goNotification(item)}
              key = {index}
            >
              <div>
                {/* <span data-notify="icon" class="nc-icon nc-bell-55"></span> */}
                <span
                  style={{ fontSize: "16px", fontWeight: 700 }}
                >{`${item.type}`}</span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#747474",
                    float: "right"
                  }}
                >{item.timestamp}
                </span>
              </div>
              <div style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{__html: item.content}} />
            </div>

          </Paper>
        </Grid>
        );
      })}
    </Grid>
    )
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <TopHeader id="top" />
        
        <div style={{margin:20}}>
          <Grid container spacing={3} style={{marginTop:120}} >
            <Grid item xs={12} sm={10}>
              <NavPills
              color="rose"
              clickOnTab={handleTabChange}

              tabs={[
                {
                    tabButton: "あなた宛",
                    tabContent: (
                      <NotificationPaper />
                    )
                },
                {
                    tabButton: "みんな宛",
                    tabContent: (
                      <NotificationPaper />
                    )
                },
              ]}
              /> 
              
            </Grid>
          </Grid>
        </div>
        <Footer />
      </Fragment>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationPage));