import React, { Fragment, useEffect, useState } from "react";

import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import ContactForms from "views/components/Home/ContactForms";

export default function ContactPage() {
  return (
    <Fragment>
      <TopHeader id="top" />
      <ContactForms />
      <Footer />
    </Fragment>
  )
}