import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, isWidthUp, withWidth, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import CodeIcon from "@material-ui/icons/Code";
import BuildIcon from "@material-ui/icons/Build";
import ComputerIcon from "@material-ui/icons/Computer";
import BarChartIcon from "@material-ui/icons/BarChart";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloudIcon from "@material-ui/icons/Cloud";
import MeassageIcon from "@material-ui/icons/Message";
import Pagination from "@material-ui/lab/Pagination";
import MaterialTable from "material-table";
import tableIcons from "./../MaterialTableIcons";
import config from "config/config";
import AvatarImage from "assets/img/faces/noname.jpg";
import Avatar from "@material-ui/core/Avatar";
import RejectIcon from "assets/img/reject.png";
import AcceptIcon from "assets/img/accept.png";
import CancelIcon from "assets/img/cancel.png";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Button from "components/CustomButtons/Button.js";
import Button2 from "components/CustomButtons/Button2.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "components/Snackbar/Snackbar.js";
import Slide from "@material-ui/core/Slide";
import AddAlert from "@material-ui/icons/AddAlert";

import CalculateSpacing from "./CalculateSpacing";
import FeatureCard from "./FeatureCard";

import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";

import * as userService from "services/userService";
import * as storageService from "services/storageService";
import { convTime } from 'utils/utils';

import styles from "../style/mainStyle";
import "../style/pagination.css";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const iconSize = 30;
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 15,
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 4,
      // borderColor: "#80bdff",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles(styles);

function FeatureSection(props) {

  const classes = useStyles();

  const { width, isAuthenticated, user, search, mobile } = props;
  const [deleteUsertModal, setDeleteUserModal] = React.useState(false);
  const [features, setFeatures] = useState([]);

  const [show, setShow] = useState(false);
  const [place, setPlace] = useState("br");
  const [notifyColor, setNotifyColor] = React.useState("danger");
  const [message, setMessage] = useState("");

  const [userId, setUserId] = React.useState(0);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  
  const [countPerPage, setCountPerPage] = React.useState(30);
  const [sortType, setSortType] = useState("nameSort");
  const [sortTypeMethod, setSortTypeMethod] = useState("asc");

  search['sortType'] = sortType;
  search['sortTypeMethod'] = sortTypeMethod;

  const [allProfileNum, setAllProfileNum] = useState(0);
  const [searchProfileNum, setSearchProfileNum] = useState(0);
  
  // please change for number per page

  const removeButtons = (id) =>
    [{ color: "rose" }].map((prop, key) => {
      return (
        <Button2
          color={prop.color}
          simple
          key={key}
          style={{ padding: "none" }}
          onClick={() => clickOpenDeleteModal(id)}
        >
          削除
        </Button2>
      );
    });

  const clickOpenDeleteModal = (id) => {
    setUserId(id);

    setDeleteUserModal(true);
  };
  const clickSubmitDelete = () => {
    // userService.deleteUser({ id: userId }).then(res => {
    //   if (res.data.message == 'success') {
    //     console.log('Success to remove');
    //     fetchUsers();
    //   }
    // }).catch((err)=>{
    //   console.log(err);
    // });

    setDeleteUserModal(false);
  };

  const fetchProfiles = () => {
    let skips = storageService.getStorage("skip");

    userService
      .getAllProfiles()
      .then((res) => {
        if (res.data.message == "success" && res.data.profile) {
          if (res.data.profile.length === 0) {
            showNotification(
              "br",
              "表示するプロファイルはありません。",
              "danger"
            );
          }
          const items = [];
          res.data.profile.map((item, index) => {
            if (!skips || !skips.includes(item.uid)) items.push(item);
          });
          setFeatures(items);
          setCount(Math.ceil(items.length / countPerPage));
          setData(items.slice(0, countPerPage));
        }
      })
      .catch((error) => {
        showNotification("br", "サーバー接続に失敗しました。", "danger");
      });
  };

  const searchProfiles = (search) => {
    let searchData = new FormData();
    search['sortType'] = sortType;
    search['sortTypeMethod'] = sortTypeMethod;

    searchData.append("search", JSON.stringify(search));

    let skips = storageService.getStorage("skip");

    userService
      .getSearchProfiles(searchData)
      .then((res) => {
        if (res.data.message == "success" && res.data.profile) {
          if (res.data.profile.length === 0) {
            // showNotification("br", "検索結果はありません。", "danger");
          }
          setSearchProfileNum(res.data.profile.length)
          setAllProfileNum(res.data.num_profile)
          const items = [];
          res.data.profile.map((item, index) => {
            if (!skips || !skips.includes(item.uid)) items.push(item);
          });
          setFeatures(items);
          setCount(Math.ceil(items.length / countPerPage));
          setData(items.slice(0, countPerPage));
        }
      })
      .catch((error) => {
        showNotification("br", "サーバー接続に失敗しました。", "danger");
      });
  };

  const handlePageChange = (event, value) => {
    console.log("value  ", value);
    setPage(value);
    let idx = value - 1;
    setData(
      features.slice(idx * countPerPage, idx * countPerPage + countPerPage)
    );
    console.log("set data  ", data);
  };

  const handleLikeChange = (uid, likeStatus) => {
    let newFeatures = [];
    for (let feature of features) {
      if (feature.uid == uid) feature.liked = likeStatus;
      newFeatures.push(feature);
    }

    setFeatures(newFeatures);
  };

  const handleChange = (event) => {
    setCountPerPage(event.target.value);
    setPage(0);
    setData(features.slice(0, event.target.value));
    setCount(Math.ceil(features.length / event.target.value));
    // console.log("per page  ", event.target.value);
  };

  const handleSortType = (event) => {
    setSortType(event.target.value);
  }

  const handleSortTypeMethod = (event) => {
    setSortTypeMethod(event.target.value);
  }

  const showNotification = (place, message, notifyColor) => {
    setPlace(place);
    setNotifyColor(notifyColor);
    setMessage(message);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const reject = (to_id, from_id) => {
    userService
      .setReject(to_id, from_id)
      .then((res) => {
        searchProfiles(search)
      });
  }
  const accept = (to_id, from_id) => {
    userService
      .setAccept(to_id, from_id)
      .then((res) => {
        searchProfiles(search)
      });
  }

  useEffect(() => {
    if (Object.keys(search).length === 0) {
      // Don't reach here
      fetchProfiles();
    } else {
      if (search.field === "")
        showNotification("br", "プロファイルフィールドを選択。", "danger");
      else searchProfiles(search);
    }
  }, [search]);

  // const classes = useStyles();
  useEffect(() => {
    searchProfiles(search);
  }, [sortType, sortTypeMethod]);

  return (
    <Paper style={{ backgroundColor: "#FFFFFF" }}>
      <Grid container style={{ padding: "10px", display: "flex", justifyContent: "flex-end" }} >
        <FormControl style={{ width: 100, paddingRight: 20}}>
          <NativeSelect
            className="my-3"
            id="demo-customized-select-native"
            value={sortType}
            onChange={handleSortType}
            input={<BootstrapInput />}
          >
            <option value="nameSort">名前</option>
            <option value="genderSort">性別</option>
            <option value="ageSort">年齢</option>
          </NativeSelect>
        </FormControl>
        <FormControl style={{ width: 80, paddingRight: 20}}>
          <NativeSelect
            className="my-3"
            id="demo-customized-select-native"
            value={sortTypeMethod}
            onChange={handleSortTypeMethod}
            input={<BootstrapInput />}
          >
            <option value="asc">昇順</option>
            <option value="desc">降順</option>
          </NativeSelect>
        </FormControl>        
        <Typography style={{ marginTop: "5px", marginRight: "15px" }}>
          検索 / 全部 : {searchProfileNum} / { allProfileNum } 
        </Typography>
        <FormControl>
          <NativeSelect
            className="my-3"
            id="demo-customized-select-native"
            value={countPerPage}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            {/* <option aria-label="None" value="" /> */}
            <option value={30}>30</option>
            <option value={60}>60</option>
            <option value={90}>90</option>
            <option value={120}>120</option>
          </NativeSelect>
        </FormControl>
        <Pagination
          className="my-3"
          count={count}
          page={page}
          siblingCount={0}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </Grid>
      <Divider />
      { !mobile &&
        <div className="container-fluid lg-p-top">
          <div className="container-fluid" style={{marginTop: "4px"}}>
            <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
            <Grid container spacing={CalculateSpacing(width)}>
              {data.map((element) => (
                // <Grid
                //   item
                //   xs={6}
                //   sm={3}
                //   md={2}
                //   lg={2}
                //   style={{ padding: "10px" }}
                //   data-aos="zoom-in-up"
                //   data-aos-delay={
                //     isWidthUp("md", width) ? element.mdDelay : element.smDelay
                //   }
                //   key={element.id}
                // >
                <FeatureCard
                  key={element.id}
                    id={element.id}
                    user={user}
                    uid={element.uid}
                    avatar={element.avatar}
                    uname={element.name}
                    createdAt={element.created_at}
                    view={search.view}
                    isSelf={user.id == element.uid}
                    // color={element.color}
                    // text={element.text}
                    searchFunc={(e) => searchProfiles(search)}
                    isLiked={element.liked}
                    isAuthenticated={isAuthenticated}
                    handleLike={handleLikeChange}
                    className={classes.featuredCard}
                    profile={element}
                />
                // </Grid>
              ))}
            </Grid>
            </div>
          </div>
        </div>
      }
      { mobile &&
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
          {data.map((element) =>
          <TableRow>
            <TableCell>
              <Avatar
              alt={`Avatar n°${element + 1}`}
              src={
                element.avatar
                  ? config.baseUrl + "avatars/" + element.avatar
                  : AvatarImage
              }
              />
            </TableCell>
            <TableCell>
              <Typography style={{ fontWeight: "bold" }}>
                {element.name}
              </Typography>              
              <Typography style={{ fontSize: "12px" }}>
                {convTime(element.created_at)}
              </Typography>              
            </TableCell>
            {
              search.view == 2 ? 
                <TableCell><img src={CancelIcon} style={{width: 20, height: 20}} onClick={() => reject(element.uid, user.id )} /></TableCell>
                : (
                  search.view == 3 && 
                (<TableCell>
                  <img src={RejectIcon} style={{width: 20, height: 20, marginRight: 20}} onClick={() => reject(user.id, element.uid)} />
                  <img src={AcceptIcon} style={{width: 20, height: 20}} onClick={() => accept(user.id, element.uid)} />
                </TableCell>)
            )}
          </TableRow>
          
          )}
          </TableBody>
        </Table>
      </TableContainer>
      }
      
      <Divider />
      { !mobile &&
        <Grid container style={{ padding: "10px", display: "flex", justifyContent: "flex-end" }} >
        <Typography style={{ marginTop: "5px", marginRight: "15px" }}>
          検索 / 全部 : {searchProfileNum} / { allProfileNum } 
        </Typography>
        <FormControl>
          <NativeSelect
            className="my-3"
            id="demo-customized-select-native"
            value={countPerPage}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            {/* <option aria-label="None" value="" /> */}
            <option value={30}>30</option>
            <option value={60}>60</option>
            <option value={90}>90</option>
            <option value={120}>120</option>
          </NativeSelect>
        </FormControl>
        <Pagination
          className="my-3"
          count={count}
          page={page}
          siblingCount={0}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </Grid>}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={deleteUsertModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteUserModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogContent>
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            style={{ textAlign: "center" }}
          >
            <h4>
              <b>本当に削除しますか？</b>
            </h4>
          </DialogTitle>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => setDeleteUserModal(false)}
              style={{ backgroundColor: "lightgrey", color: "black" }}
            >
              キャンセル
            </Button>
            <Button
              onClick={() => clickSubmitDelete()}
              style={{ backgroundColor: "#e12e3e" }}
            >
              削除する
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        place={place}
        color={notifyColor}
        icon={AddAlert}
        message={message}
        open={show}
        closeNotification={() => setShow(false)}
        close
      />
    </Paper>
  );
}

FeatureSection.propTypes = {
  width: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

export default withWidth()(FeatureSection);
