import React, { Fragment, useState, useEffect} from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom";
// @material-ui/core components
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

// core components
import NavPills from "components/NavPills/NavPills.js";

import TopHeader from "views/components/Home/TopHeader.js";
import SearchProfile from "views/Pages/SearchProfile";
import FeatureSection from "views/components/Home/FeatureSection";
import Footer from "views/components/Home/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function Match(props) {

  const classes = useStyles();
  const { user, isAuthenticated } = props;
  const [search, setSearch] = useState({});
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const tabId = parseInt(props.match.params.id);

  let userId = user.id;
  const getSearch = cond => {
    setSearch({...cond, userId: userId});
  }

  const handleTabChange = () => {
  };

  // console.log(search);
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <div className={classes.root}>
      <Fragment>
      <TopHeader id="top" />
      <div style={{margin:20}}>
        <Grid container justifyContent="center" spacing={3} style={{marginTop: 85}}>
          {/* <h2>{props.match.params.id}</h2> */}

          {!mobileView && <SearchProfile searchFunc={(e) => getSearch(e)} />}
          {!mobileView && 
            <Grid item xs={12} sm={8} md={9} lg={10}>
              <NavPills
              color="rose"
              clickOnTab={handleTabChange}
              active={tabId < 3 ? tabId : 0}

              tabs={[
                  {
                      tabButton: "マッチした人",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 1}} />
                      )
                  },
                  {
                      tabButton: "送ったNice",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 2}} />
                      )
                  },
                  {
                      tabButton: "受け取ったNice",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 3}} />
                      )
                  },
                  {
                      tabButton: "自分の👣",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 4}}/>
                      )
                  },
                  {
                      tabButton: "訪問者",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 5}} />
                      )
                  },
              ]}
              /> 
            </Grid>
          }
          {mobileView &&
            <Grid item xs={12} sm={10}>
              <NavPills
              color="rose"
              clickOnTab={handleTabChange}

              tabs={[
                  {
                      tabButton: "マッチした人",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 1}} mobile={true} />
                      )
                  },
                  {
                      tabButton: "送ったNice",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 2}} mobile={true} />
                      )
                  },
                  {
                      tabButton: "受け取ったNice",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 3}} mobile={true} />
                      )
                  },
                  {
                      tabButton: "自分の👣",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 4}} mobile={true} />
                      )
                  },
                  {
                      tabButton: "訪問者",
                      tabContent: (
                        <FeatureSection isAuthenticated={isAuthenticated} user={user} search={{...search, view: 5}} mobile={true} />
                      )
                  },
              ]}
              /> 
            </Grid>
          }
        </Grid>
      </div>
      <Footer />
      </Fragment>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Match));