import React, {
  useState,
  useCallback,
  useRef,
  Fragment,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import AvatarImage from "../../assets/img/faces/noname.jpg";
import "date-fns";

import {
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  FormControlLabel,
  withStyles,
  Switch,
  Grid,
  Box,
  Menu,
  Paper,
  Card,
  CardContent,
  Avatar
} from "@material-ui/core";
import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import Button from "../../components/CustomButtons/Button";
import Snackbar from "components/Snackbar/Snackbar.js";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import AddAlert from "@material-ui/icons/AddAlert";
import LikeIcon from "assets/img/likeStar.png";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

// import request from "../../../api/request";
import config from "config/config";
import ApproaveModal from "./Home/ApproaveModal";

import * as userService from "services/userService";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import GridContainer from "components/Grid/GridContainer";
import CustomizeMenu from "./CustomizeMenu";
import ChatContent from "./ChatContent.js";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { calcAge } from 'utils/utils';
import { calcSeiza } from 'utils/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = (theme) => ({
  link: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  right: {
    float: "right",
  },
  dropdown: {
    width: "100%",
    marginTop: "15px",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
});

const dropDownList = [
  { id: "icon", attrib: "looking", label: "探している相手", items: ["デート", "友達", "ビジネス"] },
  { id: "icon", attrib: "education", label: " 学歴", items: ["博士", "修士", "学士", "準学士", "専門士"] },
  { id: "icon", attrib: "dating", label: "交際ステータス", items: ["独身", "既婚"] },
  // { id: "icon", attrib: "qualification", label: " 資格", items: ["金融", "医療", "IT", "工学", "語学", "士業"] },
  // { id: "icon", attrib: "driverLicense", label: " 自動車免許", items: ["有", "無"] },
  // { id: "icon", attrib: "bikeLicense", label: " バイク免許", items: ["有", "無"] },
  { id: "icon", attrib: "children", label: " 子供の有無", items: ["無", "有"] },
  { id: "icon", attrib: "smoking", label: "喫煙", items: ["喫煙者", "禁煙に挑戦中", "非喫煙者"] },
  { id: "icon", attrib: "drinking", label: "飲酒", items: ["飲まない", "1ヶ月に1度以下", "1ヶ月に2～4度", "1週間に2～3度", "1週間に4度以上"] },
];

const ageSteps = [
  { start: 0, end: 14, label: "【幼少期～14歳】" },
  { start: 15, end: 19, label: "【15歳～19歳】" },
  { start: 20, end: 24, label: "【20歳～24歳】" },
  { start: 25, end: 29, label: "【25歳～29歳】" },
  { start: 30, end: 34, label: "【30歳～34歳】" },
  { start: 35, end: 39, label: "【35歳～39歳】" },
  { start: 40, end: 44, label: "【40歳～44歳】" },
  { start: 45, end: 49, label: "【45歳～49歳】" },
  { start: 50, end: 54, label: "【50歳～54歳】" },
  { start: 55, end: 59, label: "【55歳～59歳】" },
  { start: 60, end: 64, label: "【60歳～64歳】" },
  { start: 65, end: 69, label: "【65歳～69歳】" },
  { start: 70, end: 74, label: "【70歳～74歳】" },
  { start: 75, end: 79, label: "【75歳～79歳】" },
  { start: 80, end: 84, label: "【80歳～84歳】" },
  { start: 85, end: 89, label: "【85歳～89歳】" },
  { start: 90, end: 94, label: "【90歳～94歳】" },
  { start: 95, end: 99, label: "【95歳～99歳】" },
];

function SettingProfile(props) {
  const user_id = props.match.params.id;
  const profile_type = props.match.params.type;

  const { setStatus, theme, onClose, status, classes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [uid, setUId] = useState(0);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState("");
  const [blood, setBlood] = useState("");
  const [character, setCharacter] = useState("");
  const [play, setPlay] = useState("");
  const [job, setJob] = useState("");
  // const [attribute, setattribute] = useState("");
  // const [education, setEducation] = useState("");
  const [oversea, setOversea] = useState("");
  const [faith, setFaith] = useState("");
  const [animal, setAnimal] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fromCnt, setFromCnt] = useState(0);
  const [toCnt, setToCnt] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);
  const [hasTermsOfServiceError, setHasTermsOfServiceError] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const registerTermsCheckbox = useRef();
  const registerPassword = useRef();
  const registerPasswordRepeat = useRef();
  const history = useHistory();
  const [questions, setQuestions] = useState([]);

  const [answerList, setAnswerList] = useState({});
  const [ageStepMemory, setAgeStepMemory] = useState([]);
  const [ageStepAnswer, setAgeStepAnswer] = useState({});

  const [dropSelect, setDropSelect] = useState({
    gender: "",
    blood: "",
    attribute: "",
    country: "",
    state: "",
    city: "",
    birth_country: "",
    income: "",
    education: "",
    looking: "",
    dating: "",
    qualification: "",
    // driverLicense: "",
    // bikeLicense: "",
    looking: "",
    dating: "",
    children: "",
    smoking: "",
    drinking: "",  
  });


  const [show, setShow] = useState(false);
  const [place, setPlace] = useState("br");
  const [notifyColor, setNotifyColor] = React.useState("danger");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [favoriteList, setFavoriteList] = useState();
  const [showFavorites, setShowFavorites] = React.useState(false);

  const [attributes, setAttributes] = useState([]);
  const [incomes, setIncomes] = useState([]);

  const [countries, setCountries] = useState([]);
  const [locationStates, setLocationStates] = useState([]);
  const [locationCities, setLocationCities] = useState([]);

  const [hobbies, setHobbies] = useState([]);
  const [preSelectHobbies, setPreSelectHobbies] = useState([]);
  const [openHobby, setOpenHobby] = useState(false);

  const [languages, setLanguages] = useState([]);
  const [preSelectLanguages, setPreSelectLanguages] = useState([]);
  const [openLanguage, setOpenLanguage] = useState(false);

  const [sourceIncomes, setSourceIncomes] = useState([]);
  const [preSelectSourceIncomes, setPreSelectSourceIncomes] = useState([]);
  const [openSourceIncome, setOpenSourceIncome] = useState(false);

  const [likedList, setLikedList] = useState();
  const [liked, setLiked] = useState(false);
  const [chat, setChat] = useState(false);
  const [chatting, setChatting] = useState(false);

  useEffect(() => {
    userService.getProfile(user_id).then((result) => {
      if (result.data.profile !== null) {
        // id of user table for chatting
        setUId(result.data.profile.uid);
        setName(result.data.profile.name);
        setBirthday(result.data.profile.birthday);
        setGender(result.data.profile.gender);
        setBlood(result.data.profile.blood);
        // setBirth_country(result.data.profile.birth_country);
        setCharacter(result.data.profile.character);
        setPlay(result.data.profile.play);
        setJob(result.data.profile.job);
        // setEducation(result.data.profile.education);
        setOversea(result.data.profile.oversea);
        setFaith(result.data.profile.faith);
        setAnimal(result.data.profile.animal);
        // setInterest(result.data.profile.interest);
        if (result.data.profile.answer)
          setAnswerList(JSON.parse(result.data.profile.answer));
        if (result.data.profile.age_answer)
          setAgeStepAnswer(JSON.parse(result.data.profile.age_answer));
        if (result.data.profile.hobby)
          setPreSelectHobbies(JSON.parse(result.data.profile.hobby));
        if (result.data.profile.language)
          setPreSelectLanguages(JSON.parse(result.data.profile.language));
        if (result.data.profile.sourceIncome)
          setPreSelectSourceIncomes(JSON.parse(result.data.profile.sourceIncome));

        setIntroduction(result.data.profile.introduction);
        setFromCnt(result.data.profile.from);
        setToCnt(result.data.profile.to);
        setFavoriteList(result.data.profile.favoritelist);
        setDropSelect({
          gender: result.data.profile.gender,
          blood: result.data.profile.blood,
          country: result.data.profile.country,
          state: result.data.profile.state,
          city: result.data.profile.city,
          birth_country: result.data.profile.birth_country,
          // attribute: result.data.profile.attribute,
          income: result.data.profile.income,
          education: result.data.profile.education,
          qualification: result.data.profile.qualification,
          driverLicense: result.data.profile.driverLicense,
          bikeLicense: result.data.profile.bikeLicense,
          looking: result.data.profile.looking,
          dating: result.data.profile.dating,
          children: result.data.profile.children,
          smoking: result.data.profile.smoking,
          drinking: result.data.profile.drinking,
        });
        if (profile_type != null) {
          setLiked(result.data.profile.liked);
          setChatting(result.data.profile.chatting);
        }
        if (result.data.profile.avatar)
          setFile(config.baseUrl + "avatars/" + result.data.profile.avatar);

        // setAnswerList(result.data.profile.answers)
      }
    });
   
    userService.getQuestion().then((result) => {
      setQuestions(result.data.items);
    });
    userService.getLanguage().then((result) => {
      setLanguages(result.data.items);
    });
    userService.getCountries().then((result) => {
      setCountries(result.data.items);
    });
    userService.getIncome().then((result) => {
      setIncomes(result.data.items);
    });
    userService.getHobby().then((result) => {
      setHobbies(result.data.items);
    });
    userService.getSourceIncome().then((result) => {
      setSourceIncomes(result.data.items);
    });
  }, []);

  
  var memory = []; var initialAgeAnswers = {};
  useEffect(() => {
    let age = calcAge(birthday);
    ageSteps.map((item, index) => {
      if (age >= item.end || age >= item.start) {
        memory.push(item.label);
        initialAgeAnswers[index] = "";
      }
    })
    setAgeStepMemory(memory)
    setAgeStepAnswer(initialAgeAnswers)
  }, [birthday]);

  useEffect(() => {
    userService.getStates(dropSelect.country).then((result) => {
      setLocationStates(result.data.items);
    });      
  }, [dropSelect.country]);

  useEffect(() => {
    dropSelect.state && userService.getCities(dropSelect.state).then((result) => {
      setLocationCities(result.data.items);
    });      
  }, [dropSelect.state]);

  const [likedNum, setLikedNum] = useState(0);
  useEffect(() => {
      userService.getMyLiked().then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          setLikedNum(data.count);
        }
    });
  }, []);
  const showNotification = (place, message, notifyColor) => {
    setPlace(place);
    setNotifyColor(notifyColor);
    setMessage(message);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const handleShowModal = (event) => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const handleChange = (name) => (event) => {
    setDropSelect({ ...dropSelect, [name]: event.target.value });
  };


  const handleChangeCountry = (name) => (event) => {
    userService.getStates(event.target.value).then((result) => {
      setLocationStates(result.data.items);
    });
    setDropSelect({ ...dropSelect, [name]: event.target.value });
  };
  const handleChangeState = (name) => (event) => {
    userService.getCities(event.target.value).then((result) => {
      setLocationCities(result.data.items);
    });
    setDropSelect({ ...dropSelect, [name]: event.target.value });
  };

  const handleChangeAnswer = (index) => (event) => {
    setAnswerList({ ...answerList, [index]: event.target.value });
    // console.log(answerList);
  };

  const handleChangeAgeAnswer = (index) => (event) => {
    setAgeStepAnswer({ ...ageStepAnswer, [index]: event.target.value });
    // console.log(ageStepAnswer)
  };

  const [file, setFile] = useState(AvatarImage);

  const handleImageChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  const handleLike = () => {
    if (!liked) {
      userService.sendLike(user_id).then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を申請しました。", "success");
          setLiked(true);
          setChatting(data.chatting);
        } else {
          showNotification("br", "プロフィールを入力してください。", "danger");
        }
      });
    } else {
      userService.sendUnLike(user_id).then(({ status, data }) => {
        console.log(data);
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を申請しました。", "success");
          setLiked(false);
          setChatting(data.chatting);
        } else {
          showNotification("br", "プロフィールを入力してください。", "danger");
        }
      });
    }
  };

  const handleChat = () => {
    setChat(!chat);
  };

  const renderCategory = (key) => {
    switch (key) {
      case 1:
        return "自己紹介"
      case 2:
        return "性格"
      case 3:
        return "興味・関心"
      case 4:
        return "価値観"
    }
  }

  // const [anchorEl1, setAnchorEl1] = React.useState(null);
  // const handleClick1 = (event) => {
  //   setAnchorEl1(event.currentTarget);
  // };
  // const handleClose1 = () => {
  //   setAnchorEl1(null);
  // };
  // const changeQuestion1 = (key) => {
  //   let tmp1_1 = questions1_1;
  //   let tmp1_0 = questions1_0;
  //   tmp1_1.push(questions1_0[key]);
  //   tmp1_0.pop(questions1_0[key]);
  //   setQuestions1_1(tmp1_1);
  //   setQuestions1_0(tmp1_0);
  //   handleClose1();
  // }

  // const [anchorEl2, setAnchorEl2] = React.useState(null);
  // const handleClick2 = (event) => {
  //   setAnchorEl2(event.currentTarget);
  // };
  // const handleClose2 = () => {
  //   setAnchorEl2(null);
  // };
  // const changeQuestion2 = (key) => {
  //   let tmp2_1 = questions2_1;
  //   let tmp2_0 = questions2_0;
  //   tmp2_1.push(questions2_0[key]);
  //   tmp2_0.pop(questions2_0[key]);
  //   setQuestions2_1(tmp2_1);
  //   setQuestions2_0(tmp2_0);
  //   handleClose2();
  // }

  // const [anchorEl4, setAnchorEl4] = React.useState(null);
  // const handleClick4 = (event) => {
  //   setAnchorEl4(event.currentTarget);
  // };
  // const handleClose4 = () => {
  //   setAnchorEl4(null);
  // };
  // const changeQuestion4 = (key) => {
  //   let tmp4_1 = questions4_1;
  //   let tmp4_0 = questions4_0;
  //   tmp4_1.push(questions4_0[key]);
  //   tmp4_0.pop(questions4_0[key]);
  //   setQuestions4_1(tmp4_1);
  //   setQuestions4_0(tmp4_0);
  //   handleClose4();
  // }

  const onSubmit = () => {
    let profileData = new FormData();
    profileData.append("name", name);
    profileData.append("birthday", birthday);
    profileData.append("gender", dropSelect.gender);
    profileData.append("blood", dropSelect.blood);
    // profileData.append("attribute", dropSelect.attribute);
    profileData.append("country", dropSelect.country);
    profileData.append("state", dropSelect.state);
    profileData.append("city", dropSelect.city);
    profileData.append("birth_country", dropSelect.birth_country);
    profileData.append("income", dropSelect.income);
    // profileData.append("character", character);
    // profileData.append("play", play);
    profileData.append("job", job);
    profileData.append("education", dropSelect.education);
    // profileData.append("driverLicense", dropSelect.driverLicense);
    profileData.append("qualification", dropSelect.qualification);
    // profileData.append("bikeLicense", dropSelect.bikeLicense);
    profileData.append("looking", dropSelect.looking);
    profileData.append("dating", dropSelect.dating);
    profileData.append("children", dropSelect.children);
    profileData.append("smoking", dropSelect.smoking);
    profileData.append("drinking", dropSelect.drinking);
    profileData.append("oversea", oversea);
    // profileData.append("faith", faith);
    profileData.append("animal", animal);
    // profileData.append("interest", interest);
    // profileData.append("introduction", introduction);
    let answer = JSON.stringify(answerList);
    profileData.append("answer", answer);

    let ageAnswer = JSON.stringify(ageStepAnswer);
    profileData.append("age_answer", ageAnswer);
    
    profileData.append("file", selectedFile);
    
    let hobby = JSON.stringify(preSelectHobbies);
    profileData.append("hobby", hobby);

    let language = JSON.stringify(preSelectLanguages);
    profileData.append("language", language);
    
    let sourceIncome = JSON.stringify(preSelectSourceIncomes);
    profileData.append("sourceIncome", sourceIncome);
    
    userService
      .updateProfile(profileData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          showNotification("br", "プロフィール編集を完了しました。", "success");
        } else if (status === 422) {
          showNotification("br", "すべての項目を記入してください。", "danger");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const register = useCallback(() => {
    if (!registerTermsCheckbox.current.checked) {
      setHasTermsOfServiceError(true);
      return;
    }
    if (
      registerPassword.current.value !== registerPasswordRepeat.current.value
    ) {
      setStatus("passwordsDontMatch");
      return;
    }
    setStatus(null);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, [
    setIsLoading,
    setStatus,
    setName,
    setHasTermsOfServiceError,
    registerPassword,
    registerPasswordRepeat,
    registerTermsCheckbox,
  ]);

  function MyTextareaAutosize(props) {
    const {
      minRows,
      placeholder,
      value,
      onChangeHandler
    } = props;

    return (
      <TextareaAutosize
        minRows={minRows}
        aria-label="minRows height"
        placeholder={placeholder}
        style={{width: "100%"}}
        value={value}
        onChange={onChangeHandler}
        disabled={profile_type != null}
      />
    );
  }

  return (
    <>
      <TopHeader id="top" />
      <Box style={{padding: 20}}>
        <Paper style={{marginTop: 100, marginBottom: 20}}>
          <Grid container justifyContent="center" spacing={3} style={{paddingTop: 30}}>
            <Grid item xs={9} sm={4} md={2} data-aos="zoom-in-up">
              <Card style={{ border: "none", boxShadow: "none" }}>
                <CardContent>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <Avatar
                    variant="rounded"
                    src={file}
                    alt="avatar"
                    style={{ width: "auto", height: "auto" }}
                  />
                  {profile_type == null &&
                    <label htmlFor="icon-button-file" style={{ marginLeft: "37%" }}>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  }
                </CardContent>
                <CardContent>
                {profile_type != null ? (
                  <div style={{justifyContent: 'center', display: "flex", paddingTop: 0}}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {name}
                    </Typography>
                  </div>
                  ) : (
                  <TextField
                    style={{ width: "100%"}}
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    label="名前"
                    autoComplete="off"
                    type="text"
                    value={name}
                    disabled={profile_type != null}
                    onChange={(event) => {
                      if (status === "invalidName") {
                        setStatus(null);
                      }
                      setName(event.target.value);
                    }}
                    formhelpertextprops={{ error: true }}
                  />
                )}
                </CardContent>
                <CardContent>
                  <Typography variant="subtitle1" align="center">受け取ったNice: {likedNum}人</Typography>
                </CardContent>
                <CardContent>
                  <Typography>
                    自己紹介
                  </Typography>
                  <TextareaAutosize
                    minRows={3}
                    aria-label="minRows height"
                    placeholder="自由記述"
                    style={{width: "100%"}}
                    value={introduction}
                    disabled={profile_type != null}
                    onChange={(event) => {
                      setIntroduction(event.target.value);
                    }}
                  />
                </CardContent>
{/*                     
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Typography variant="subtitle1">
                      フォロー数: {favoriteList ? favoriteList.length : 0}人
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showFavorites}
                          onChange={() => setShowFavorites(!showFavorites)}
                        />
                      }
                      label={
                        <span style={{ fontSize: "11px" }}>フォローを表示する</span>
                      }
                    />
                  </div> */}
                {profile_type != null && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleLike();
                      }}
                      color="rose"
                      style={{ marginTop: 30 }}
                    >
                      <img src={LikeIcon} style={{ width: "20px", height: "20px", marginRight: "10px"}}></img>
                      {liked ? "Niceをキャンセル" : "Niceを送る"}
                    </Button>
                  </div>
                )}
                {/* {profile_type != null && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      color="twitter"
                      disabled={!chatting}
                      startIcon={<ThumbUpIcon />}
                      style={{ marginTop: 10 }}
                      onClick={() => handleChat()}
                    >
                      {chat ? "プロフィール" : "チャット"}
                    </Button>
                  </div>
                )} */}
              </Card>
            </Grid>
            <Grid item xs={12} sm={7} md={4} data-aos="zoom-in-up" style={{ margin: 10 }}>
              <Card>
                <CardContent>
                  <Typography align="left" variant="subtitle1">
                    <b>基本情報</b>
                  </Typography>
                </CardContent>
                <Divider />
                <CardContent>
                  {/* <Grid container>
                    <Grid item xs={12} sm={12} md={12} data-aos="zoom-in-up" style={{padding: 10}}> */}
                      {/* <TextField
                        style={{ width: "100%"}}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        // error={status === "invalidName"}
                        label="氏名"
                        autoComplete="off"
                        type="text"
                        value={name}
                        onChange={(event) => {
                          if (status === "invalidName") {
                            setStatus(null);
                          }
                          setName(event.target.value);
                        }}
                        formhelpertextprops={{ error: true }}
                      /> */}
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          性別
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={dropSelect.gender}
                          onChange={handleChange("gender")}
                          label="性別"
                          disabled={profile_type != null}
                        >
                          <MenuItem value={"男性"}>男性</MenuItem>
                          <MenuItem value={"女性"}>女性</MenuItem>
                          <MenuItem value={"その他"}>その他</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        style={{ width: "100%"}}
                        size="small"
                        id="date"
                        label="生年月日"
                        margin="normal"
                        type="date"
                        value={birthday}
                        variant="outlined"
                        className={classes.textField}
                        disabled={profile_type != null}
                        onChange={(event) => {
                          setBirthday(event.target.value);
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField size="small"
                        id="age"
                        label="年齢"
                        margin="normal"
                        type="age"
                        disabled
                        value={calcAge(birthday)}
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                      />
                      <TextField size="small"
                        id="sign"
                        label="星座"
                        margin="normal"
                        disabled
                        value={calcSeiza(birthday)}
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                      />
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          血液型
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={dropSelect.blood}
                          disabled={profile_type != null}
                          onChange={handleChange("blood")}
                          label="血液型"
                        >
                          <MenuItem value={"O"}>O</MenuItem>
                          <MenuItem value={"A"}>A</MenuItem>
                          <MenuItem value={"B"}>B</MenuItem>
                          <MenuItem value={"AB"}>AB</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          居住国
                        </InputLabel>
                        <Select
                          value={dropSelect.country}
                          onChange={handleChangeCountry("country")}
                          disabled={profile_type != null}
                          label="居住国"
                        >
                          {countries && countries.map((item, key) => (
                            <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          居住州
                        </InputLabel>
                        <Select
                          value={dropSelect.state}
                          onChange={handleChangeState("state")}
                          disabled={profile_type != null}
                          label="居住州"
                        >
                          {locationStates && locationStates.map((item, key) => (
                            <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          居住都市
                        </InputLabel>
                        <Select
                          value={dropSelect.city}
                          onChange={handleChange("city")}
                          disabled={profile_type != null}
                          label="居住都市"
                        >
                          {locationCities && locationCities.map((item, key) => (
                            <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <Autocomplete
                          multiple
                          open={openLanguage}
                          onOpen={() => {
                            setOpenLanguage(true);
                          }}
                          onClose={() => {
                            setOpenLanguage(false);
                          }}
                          id="size-small-outlined-multi"
                          size="small"
                          limitTags={5}
                          options={languages}
                          value={preSelectLanguages}
                          disableCloseOnSelect
                          disabled={profile_type != null}
                          getOptionLabel={(option) => option}
                          renderOption={(option, { selected }) => {
                            if (preSelectHobbies.includes(option)) {
                              selected = true;
                            }
                            return (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option}
                              </React.Fragment>
                            );
                          }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="使用言語"
                            autoComplete="off"
                          />
                        )}
                        onChange={(e, v) => {
                          setPreSelectLanguages(v);
                        }}
                      />
                      </FormControl>
                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                        出身国
                        </InputLabel>
                        <Select
                          value={dropSelect.birth_country}
                          onChange={handleChange("birth_country")}
                          disabled={profile_type != null}
                          label="出身国"
                        >
                          {countries && countries.map((item, key) => (
                            <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        style={{ width: "100%"}}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        // error={status === "invalidName"}
                        label="海外経験"
                        autoComplete="off"
                        type="text"
                        value={oversea}
                        disabled={profile_type != null}
                        onChange={(event) => {
                          setOversea(event.target.value);
                        }}
                        formhelpertextprops={{ error: true }}
                      />

                      <FormControl
                        size="small"
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          年収
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={dropSelect.income}
                          disabled={profile_type != null}
                          onChange={handleChange("income")}
                          label="年収"
                        >
                          {incomes && incomes.map((item, key) => (
                            <MenuItem value={item.id} key={key}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Autocomplete
                        style={{marginTop: 15}}
                        multiple
                        open={openSourceIncome}
                        onOpen={() => {
                          setOpenSourceIncome(true);
                        }}
                        onClose={() => {
                          setOpenSourceIncome(false);
                        }}
                        id="size-small-outlined-multi"
                        size="small"
                        limitTags={5}
                        options={sourceIncomes}
                        value={preSelectSourceIncomes}
                        disableCloseOnSelect
                        disabled={profile_type != null}
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => {
                          if (preSelectSourceIncomes.includes(option)) {
                            selected = true;
                          }
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </React.Fragment>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="収入源"
                            autoComplete="off"
                          />
                        )}
                        onChange={(e, v) => {
                          setPreSelectSourceIncomes(v);
                        }}
                      />
                      <TextField
                        style={{ width: "100%"}}
                        size="small"
                        variant="outlined"
                        margin="normal"
                        required
                        // error={status === "invalidName"}
                        label="職業"
                        placeholder="(例: 業界、職種などを記載してください)"
                        autoComplete="off"
                        type="text"
                        value={job}
                        disabled={profile_type != null}
                        onChange={(e) => { setJob(e.target.value); }}
                        formhelpertextprops={{ error: true }}
                      />                        
                      {dropDownList.map((item, index) => (
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.dropdown}
                          key={index}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            {item.label}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={dropSelect[item.attrib]}
                            disabled={profile_type != null}
                            onChange={handleChange(item.attrib)}
                            label={item.label}
                          >
                            {item.items.map((subitem, index) => (
                              <MenuItem key={index} value={subitem}>{subitem}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ))}
                      <Autocomplete
                        style={{marginTop: 15}}
                        multiple
                        open={openHobby}
                        onOpen={() => {
                          setOpenHobby(true);
                        }}
                        onClose={() => {
                          setOpenHobby(false);
                        }}
                        id="size-small-outlined-multi"
                        size="small"
                        limitTags={5}
                        options={hobbies}
                        value={preSelectHobbies}
                        disableCloseOnSelect
                        disabled={profile_type != null}
                        getOptionLabel={(option) => option}
                        renderOption={(option, { selected }) => {
                          if (preSelectHobbies.includes(option)) {
                            selected = true;
                          }
                          return (
                            <React.Fragment>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </React.Fragment>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="好きなスポーツ"
                            autoComplete="off"
                          />
                        )}
                        onChange={(e, v) => {
                          setPreSelectHobbies(v);
                        }}
                      />
                      <Typography style={{marginTop: 15}} variant="subtitle2">
                        好きな動物
                      </Typography>
                      <TextareaAutosize
                        minRows={2}
                        aria-label="minRows height"
                        placeholder="(例: 好きな動物や飼育経験などを教えてください)"
                        style={{width: "100%"}}
                        value={animal}
                        onChange={(e) => { setAnimal(e.target.value); }}
                        disabled={profile_type != null}                 
                      />
                      {/* <MyTextareaAutosize
                        minRows={2}
                        placeholder="(例: 好きな動物や飼育経験などを教えてください)"
                      /> */}
                    {/* </Grid>
                  </Grid> */}
                </CardContent>
                <Divider />
                <CardContent>
                  <Typography align="left" variant="subtitle1">
                    <b>詳細情報</b>
                  </Typography>
                </CardContent>
                <Divider />
                <CardContent>
                  {/* <Grid container>
                    <Grid item xs={12} sm={12} md={5} data-aos="zoom-in-up" style={{padding: 10}}> */}
                      <Typography variant="subtitle1">
                        <b>一般</b>
                      </Typography>
                      {questions.map((item, index) => (
                        item.category == 1 &&
                          <>
                            <Typography style={{marginTop: 15}} variant="subtitle2">
                              {item.contents}
                            </Typography>
                            <TextareaAutosize
                              minRows={3}
                              aria-label="minRows height"
                              placeholder="自由記述"
                              style={{width: "100%"}}
                              value={ answerList[item.id] }
                              onChange={handleChangeAnswer(item.id)}
                              disabled={profile_type != null}
                            />
                                                          {/* <MyTextareaAutosize
                              minRows={3}
                              placeholder="自由記述"
                              value={ answerList[item.id] }
                              onChange={handleChangeAnswer(item.id)}
                            /> */}
                          </>
                      ))}

                      <Typography variant="subtitle1">
                        <b>思い出</b>
                      </Typography>
                      {questions.map((item, index) => (
                        item.category == 2 &&
                          <>
                            <Typography style={{marginTop: 15}} variant="subtitle2">
                              {item.contents}
                            </Typography>
                            <TextareaAutosize
                              minRows={3}
                              aria-label="minRows height"
                              placeholder="自由記述"
                              style={{width: "100%"}}
                              value={ answerList[item.id] }
                              onChange={handleChangeAnswer(item.id)}
                              disabled={profile_type != null}
                            />                              
                          </>
                      ))}
                      {ageStepMemory.map((item, index) => (
                        <>
                          <Typography style={{marginTop: 15}} variant="subtitle2">
                            { item }熱中したことや思い出に残っていることは何ですか？
                          </Typography>
                          <TextareaAutosize
                            minRows={3}
                            aria-label="minRows height"
                            placeholder="自由記述"
                            style={{width: "100%"}}
                            value={ageStepAnswer[index]}
                            onChange={handleChangeAgeAnswer(index)}
                            disabled={profile_type != null}
                          />
                        </>
                      ))}
                    {/* </Grid>
                  </Grid> */}
                  {/* <Grid container>
                    <Grid item xs={12} sm={12} md={5} data-aos="zoom-in-up" style={{padding: 10}}> */}
                      <Typography variant="subtitle1">
                        <b>価値観、こだわり</b>
                      </Typography>
                      {questions.map((item, index) => (
                        item.category == 3 &&
                          <>
                            <Typography style={{marginTop: 15}} variant="subtitle2">
                              {item.contents}
                            </Typography>
                            <TextareaAutosize
                              minRows={3}
                              aria-label="minRows height"
                              placeholder="自由記述"
                              style={{width: "100%"}}
                              value={ answerList[item.id] }
                              onChange={handleChangeAnswer(item.id)}
                              disabled={profile_type != null}
                            />
                          </>
                      ))}
                      <Typography variant="subtitle1">
                        <b>興味・関心・趣味・特技</b>
                      </Typography>
                      {questions.map((item, index) => (
                        item.category == 4 &&
                          <>
                            <Typography style={{marginTop: 15}} variant="subtitle2">
                              {item.contents}
                            </Typography>
                            <TextareaAutosize
                              minRows={3}
                              aria-label="minRows height"
                              placeholder="自由記述"
                              style={{width: "100%"}}
                              value={ answerList[item.id] }
                              onChange={handleChangeAnswer(item.id)}
                              disabled={profile_type != null}
                            />
                          </>
                      ))}
                      <Typography style={{marginTop: 15, marginBottom: 15}} variant="subtitle1">
                        <b>その他</b>
                      </Typography>
                      {questions.map((item, index) => (
                        item.category == 5 &&
                          <TextareaAutosize
                            minRows={3}
                            aria-label="minRows height"
                            placeholder="自由記述"
                            style={{width: "100%"}}
                            value={ answerList[item.id] }
                            onChange={handleChangeAnswer(item.id)}
                            disabled={profile_type != null}
                          />
                      ))}
                      {/* {profile_type == null &&
                      <Button size="sm" style={{float: "right"}}>
                        更に追加する＋
                      </Button>
                      } */}
                    {/* </Grid>
                  </Grid> */}
                </CardContent>
                <CardContent>
                  <Grid container>
                    <Grid item xs={profile_type != null ? 12 : 6}>
                      <Grid container justifyContent="center">
                        <a
                          href="/"
                          style={{ color: "#e12e3e" }}
                          rel={"noindex nofollow"}
                        >
                          <Button
                            size="md"
                            // onClick={() => handleLogOut()}
                            color="success"
                            style={{backgroundColor: "#ff0000"}}
                          >
                            戻る
                          </Button>
                        </a>
                      </Grid>
                    </Grid>
                    {profile_type == null &&
                    <Grid item xs={6}>
                      <Grid container justifyContent="center">
                        <Button
                          onClick={onSubmit}
                          size="md"
                          color="success"
                          style={{backgroundColor: "#ff0000"}}
                        >
                          保存する
                        </Button>
                      </Grid>
                    </Grid>
                    }
                  </Grid>
                  <Snackbar
                    place={place}
                    color={notifyColor}
                    icon={AddAlert}
                    message={message}
                    open={show}
                    closeNotification={() => setShow(false)}
                    close
                  />
                </CardContent>
              </Card>
            </Grid>
            {chatting &&
              <Grid item xs={12} sm={12} md={5} ata-aos="zoom-in-up"
                style={{ margin: 10 }}
              >
                <Card>
                  <ChatContent uid={uid} />
                </Card>
              </Grid>
            }
          </Grid>
        </Paper>
      </Box>
      <Footer />
    </>
  );
}

SettingProfile.propTypes = {
  theme: PropTypes.object,
  onClose: PropTypes.func,
  openTermsDialog: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(SettingProfile);
