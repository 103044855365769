import React, { Fragment, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import { CardContent, Typography, withStyles } from "@material-ui/core";
import AvatarImage from "assets/img/faces/noname.jpg";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardFooter from "components/Card/CardFooter.js";
import AddAlert from "@material-ui/icons/AddAlert";
import InfoIcon from "@material-ui/icons/Info";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LikeIcon from "assets/img/likeStar.png";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button";
import Snackbar from "components/Snackbar/Snackbar.js";

import config from "config/config";
import * as userService from "services/userService";
import * as storageService from "services/storageService";
import { convTime } from 'utils/utils';

import RejectIcon from "assets/img/reject.png";
import AcceptIcon from "assets/img/accept.png";
import CancelIcon from "assets/img/cancel.png";

import { connect } from "react-redux";
import "views/components/style/imagecard.css";
import { calcAge } from 'utils/utils';

const theme = createTheme({
  //v5.0.0
  typography: {
    body1: {
      fontSize: [14, "!important"]
    },
    body2: {
        fontSize: [20, "!important"],
        fontWeight: "bold"
    },
    body3: {
        fontSize: [12, "!important"],
    }
  },
  //older versions
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [20, "!important"]
      },
      body3: {
        fontSize: [12, "!important"],
      }
    }
  }
});

const styles = (theme) => ({
  iconWrapper: {
    // borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
    // padding: theme.spacing(1) * 1.5
  },
  color: {
    color: "#499BEF",
  },
  button: {
    backgroundColor: "#E5E5E5",
    color: "#1E1E1E !important",
    fontSize: "16px",
  },
  overrides: {
    MuiButton: {
      Button: {
        color: "black",
      },
    },
  },
  avatar: {
    width: "100%",
    height: "100%",
  },
  imageCenter: {
    margin: "auto",
    display: "flex",
  },

  featuredCard: {
    width: "11%",
    margin: "10px",
    "@media (min-width: 1376px) and (max-width: 1664px)": {
      width: "15%",
      margin: "10px auto"
    },
    "@media (min-width: 1024px) and (max-width: 1377px)": {
      width: "18%",
      margin: "10px auto"
    },
    "@media (min-width: 700px) and (max-width: 1025px)": {
      width: "31%",
      margin: "10px auto"
    },
    "@media (min-width: 450px) and (max-width: 701px)": {
      width: "31%",
      margin: "10px auto"
    },
    "@media screen and (max-width: 451px)": {
      width: "45%",
      margin: "10px auto"
    }
  },

  featuredCardInfo: {
    width: "22%",
    margin: "10px",
    "@media (min-width: 1376px) and (max-width: 1664px)": {
      width: "30%",
      margin: "10px auto"
    },
    "@media (min-width: 1024px) and (max-width: 1377px)": {
      width: "36%",
      margin: "10px auto"
    },
    "@media (min-width: 700px) and (max-width: 1025px)": {
      width: "62%",
      margin: "10px auto"
    },
    "@media (min-width: 450px) and (max-width: 701px)": {
      width: "62%",
      margin: "10px auto"
    },
    "@media screen and (max-width: 451px)": {
      width: "90%",
      margin: "10px auto"
    }
  }
});

function shadeColor(hex, percent) {
  const f = parseInt(hex.slice(1), 16);

  const t = percent < 0 ? 0 : 255;

  const p = percent < 0 ? percent * -1 : percent;

  const R = f >> 16;

  const G = (f >> 8) & 0x00ff;

  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

function FeatureCard(props) {
  const {
    avatar,
    user,
    classes,
    color,
    uname,
    createdAt,
    view,
    isSelf,
    text,
    id,
    isLiked,
    isAuthenticated,
    uid,
    profile,
    searchFunc
  } = props;

  const [show, setShow] = useState(false);
  const [place, setPlace] = useState("br");
  const [notifyColor, setNotifyColor] = useState("danger");
  const [message, setMessage] = useState("");
  const [liked, setLiked] = useState(isLiked);
  const [skipped, setSkipped] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleLike = () => {
    if (!liked) {
      userService.sendLike(uid).then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を申請しました。", "success");
          setLiked(true);
          props.handleLike(uid, true);
        } else {
          showNotification("br", "プロフィールを入力してください。", "danger");
        }
      });
    } else {
      userService.sendUnLike(uid).then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を解除しました。", "success");
          setLiked(false);
          props.handleLike(uid, false);
        } else {
          showNotification("br", "プロフィールを入力してください。", "danger");
        }
      });
    }
  };

  const handleSkip = () => {
    let skips = storageService.getStorage("skip");
    skips.push(uid);

    storageService.setStorage("skip", skips);
    setSkipped(true);
  };

  const showNotification = (place, message, notifyColor) => {
    setPlace(place);
    setNotifyColor(notifyColor);
    setMessage(message);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
  };

  const visitDel = (from_id, to_id) => {
    userService
      .setVisitDel(from_id, to_id)
      .then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "", "danger");
        } else {
        }
        searchFunc();
      });
  }
  const reject = (to_id, from_id) => {
    userService
      .setReject(to_id, from_id)
      .then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を断りました。", "danger");
        } else {
        }
        searchFunc();
      });
  }
  const accept = (to_id, from_id) => {
    userService
      .setAccept(to_id, from_id)
      .then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          showNotification("br", "「Nice」を承諾しました。", "success");
        } else {
        }
        searchFunc();
      });
  }

  const toggleShow = () => {
    setShowInfo(!showInfo);
  }

  const history = useHistory();
  const profileImg = avatar
    ? config.baseUrl + "avatars/" + avatar
    : AvatarImage;

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
      <Card variant="outlined" className={ showInfo ? classes.featuredCardInfo : classes.featuredCard}>
        <CardAvatar>
          {isAuthenticated ? (<>
            <a href={"/personalProfile/" + uid + "/he"} style={{ color: "#499BEF" }}>
              <div className="showImage">
                <img
                  src={profileImg}
                  className={classes.avatar}
                  alt="avatar"
                  style={{ height: "180px" }}
                  />
                <InfoIcon className="update" onMouseOver={() => toggleShow()}/>
                {/* <input className="update" type="button" value="Update"  onMouseOver={() => toggleShow()}/> */}
                {/* <input className="delete" type="button" value="Delete" /> */}
              </div>
              {/* <img
                src={profileImg}
                className={classes.avatar}
                alt="avatar"
                style={{ height: 180 }}
              /> */}
            </a>
            {showInfo &&
              <div>
                <br />
                <Typography variant="body2" style={{textAlign: "center" }}>{uname}</Typography>
                <br />
                <Typography variant="body2" style={{textAlign: "center" }}>
                  <span style={{marginRight: 10}}>{profile.gender}</span>
                  <span style={{marginRight: 10}}>{calcAge(profile.birthday)}</span>
                  </Typography>
                <br />
                <Typography variant="body1" style={{textAlign: "center" }}>{profile.income}</Typography>
              </div>
            }
            </>
          ) : (
            <img
              src={profileImg}
              className={classes.avatar}
              alt="avatar"
              style={{ height: "180px" }}
            />
          )}
        </CardAvatar>
        {!isSelf && !view && (
          <div>
            <Typography variant="body1" style={{textAlign: "center" }}>{uname}</Typography>
            <Button
              variant="outlined"
              onClick={() => {
                isAuthenticated && handleLike();
              }}
              color="rose"
              // startIcon={<FavoriteIcon />}
              style={{ width: "100%", margin: 0, padding: 7 }}
            >
              <img src={LikeIcon} style={{ width: "25px", height: "25px", marginRight: 5 }} />
              <span style={{marginTop: 5}}>
                {liked ? "Nice をキャンセル" : "Nice !"}
              </span>
            </Button>
          </div>
        )}
        { view &&
        <CardContent style={{padding: 10}}>
          <Typography variant="body2" style={{textAlign: "center" }}>{uname}</Typography>
          <div variant="body3" style={{textAlign: "center" }}>{convTime(createdAt)}</div>
          {view == 2 &&
            <img src={CancelIcon} title="Niceをキャンセル" style={{width: 25, height: 25, cursor: "pointer", marginTop: 10}} className={classes.imageCenter}  onClick={() => reject(uid, user.id )}/>  
          }
          {view == 3 && 
            (<Fragment>
              <img src={RejectIcon} title="Nice要請を断る" style={{width: 25, height: 25, cursor: "pointer", marginLeft: 30, marginTop: 10}} onClick={() => reject(user.id, uid)}/>
              <img src={AcceptIcon} title="Nice要請を承諾する" style={{width: 25, height: 25, float: "right", cursor: "pointer", marginRight: 30, marginTop: 10}} onClick={() => accept(user.id, uid)} />
            </Fragment>)
          }
          {view == 4 &&
            <img src={CancelIcon} title="" style={{width: 25, height: 25, cursor: "pointer", marginTop: 10}} className={classes.imageCenter} onClick={() => visitDel(user.id, uid)}/>  
          }
          {view == 5 &&
            <img src={CancelIcon} title="" style={{width: 25, height: 25, cursor: "pointer", marginTop: 10}} className={classes.imageCenter} onClick={() => visitDel(uid, user.id)}/>  
          }
        </CardContent>
        }
        {/* {!isSelf && !liked && (
          <Button
            onClick={() => {
              isAuthenticated && handleSkip();
            }}
            variant="outlined"
            color="facebook"
            startIcon={<ThumbUpIcon />}
            disabled={skipped}
            style={{ margin: 5 }}
          >
            Interested
          </Button>
        )} */}
      </Card>
      <Snackbar
        place={place}
        color={notifyColor}
        icon={AddAlert}
        message={message}
        open={show}
        closeNotification={() => setShow(false)}
        close
      />
      </ThemeProvider>
    </Fragment>
  );
}

FeatureCard.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(FeatureCard);
// export default connect(mapStateToProps)(withRouter(FeatureCard))
