import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Route, Switch, Redirect, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { Grid, Box, Typography, isWidthUp, withWidth, InputLabel, Paper } from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";

import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import SearchProfile from "views/Pages/SearchProfile";
import TopCarousel from "views/components/Home/TopCarousel.js";
import FeatureSection from "views/components/Home/FeatureSection";

import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function Home(props) {
  const classes = useStyles();
  const { user, isAuthenticated } = props;
  const [search, setSearch] = useState({});
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const getSearch = cond => {
    setSearch(cond);
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);


  return (
    <div className={classes.root}>
      <Fragment>
      <TopHeader id="top" />
      {/* <TopCarousel isAuthenticated={isAuthenticated} /> */}
      <div style={{margin:20}}>
        <Grid container spacing={2} style={{marginTop: 100}} >
          <SearchProfile searchFunc={(e) => getSearch(e)} />
          <Grid item xs={12} sm={9} lg={10}>
            <FeatureSection isAuthenticated={isAuthenticated} user={user} search={search} />
          </Grid>
        </Grid>
      </div>
      <Footer />
      </Fragment>
    </div>
  )}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));