import React, { Fragment, useEffect, useState } from "react";

import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import PrivacyContent from "views/components/Home/PrivacyContent";

export default function ContactPage() {
  return (
    <Fragment>
      <TopHeader id="top" />
      <PrivacyContent />
      <Footer />
    </Fragment>
  )
}