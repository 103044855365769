import React from "react";
import moment from "moment";

export const convTime = (srcTime) => {
    const today = moment().format("YYYY-MM-DD");
    const itemDay = moment(srcTime).format("YYYY-MM-DD");
    let newTime;
    if (today === itemDay) {
        newTime = moment(srcTime).format("HH:mm");
    }
    else {
        const todayY = moment().format("YYYY");
        const itemY = moment(srcTime).format("YYYY");
        if (todayY === itemY)
        newTime = moment(srcTime).format("M月D日");
        else
        newTime = moment(srcTime).format("YYYY年M月");
    }
    return newTime;
};

export const calcAge = (birthday) => {
    if (!birthday) return "";

    var tDate = new Date();
    var sDate = new Date(birthday);
    var age = tDate.getFullYear() - sDate.getFullYear();
    var mDate = new Date(tDate.getFullYear() + "/" + (sDate.getMonth() + 1) + "/" + sDate.getDate());
    if (tDate < mDate) age = age - 1;

    return age;
};

export const gengoChack = (ymd) => {
    var gengo = ymd.substr(0,2)
    var dif
    switch(gengo) {
        case '明治': dif = 1867; break; // 元号の開始年 -1 を設定
        case '大正': dif = 1911; break;
        case '昭和': dif = 1925; break;
        case '平成': dif = 1988; break;
        case '令和': dif = 2018; break;
        default: return (ymd);  // 西暦をそのまま返す
    }
    var nen = ymd.split(gengo)[1].split('年')[0]
    var tuki = ymd.split('年')[1].split('月')[0]
    var hi = ymd.split('月')[1].split('日')[0]
    nen = Number(nen) + Number(dif)
    return nen + "/" + tuki + "/" + hi  // 和暦を西暦に変換した値を返す
};

export const calcSeiza = (birthday) => {
    const sDate = new Date(birthday);
    const MM = sDate.getMonth() + 1;
    const DD = sDate.getDate();
    var ans = "";

    if (MM == 3 && DD >= 21) ans  = "牡羊"
    if (MM == 4 && DD <=19) ans  = "牡羊"
    if (MM == 4 && DD >= 20) ans  = "牡牛"
    if (MM == 5 && DD <=20) ans  = "牡牛"
    if (MM == 5 && DD >= 21) ans  = "双子"
    if (MM == 6 && DD <=21) ans  = "双子"
    if (MM == 6 && DD >= 22) ans  = "蟹"
    if (MM == 7 && DD <=22) ans  = "蟹"
    if (MM == 7 && DD >= 23) ans  = "獅子"
    if (MM == 8 && DD <=22) ans  = "獅子"
    if (MM == 8 && DD >= 23) ans  = "乙女"
    if (MM == 9 && DD <=22) ans  = "乙女"
    if (MM == 9 && DD >= 23) ans  = "天秤"
    if (MM == 10 && DD <=23) ans  = "天秤"
    if (MM == 10 && DD >= 24) ans  = "蠍"
    if (MM == 11 && DD <=21) ans  = "蠍"
    if (MM == 11 && DD >= 22) ans  = "射手"
    if (MM == 12 && DD <=21) ans  = "射手"
    if (MM == 12 && DD >= 22) ans  = "山羊"
    if (MM == 1 && DD <=19) ans  = "山羊"
    if (MM == 1 && DD >= 20) ans  = "水瓶"
    if (MM == 2 && DD <=18) ans  = "水瓶"
    if (MM == 2 && DD >= 19) ans  = "魚"
    if (MM == 3 && DD <=20) ans  = "魚"
    return ans + "座";
};