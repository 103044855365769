import React, { Fragment, useEffect, useState } from "react";
import MaterialTable from "material-table";
import tableIcons from "./MaterialTableIcons";

import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Switch from "@material-ui/core/Switch";
import { withStyles, Grid } from "@material-ui/core";
import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
import config from "config/config";
import AvatarImage from "assets/img/faces/noname.jpg";

import TextField from '@material-ui/core/TextField';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from '@material-ui/core/DialogContentText';
import People from "@material-ui/icons/PeopleRounded";

import * as userService from "services/userService";

import styles from "./style/mainStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AdminUser(props) {
  const [users, setUsers] = React.useState([]);
  const [modifyPwModal, setModifyPwModal] = React.useState(false);
  const [deleteUserModal, setDeleteUserModal] = React.useState(false);
  const [userId, setUserId] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState();
  const [newPw, setNewPw] = useState('');

  useEffect(() => {
    userService.getUsers().then((res) => {
      if (res.data.message == "success" && res.data.users) {
        setUsers(res.data.users);
      } else {
        console.log("error");
      }
    });
  }, []);

  const columns = [
    {
      title: "写真",
      field: "avatar",
      render: (rowData) => (
        <Avatar
          alt={`Avatar n°${rowData + 1}`}
          src={
            rowData.avatar
              ? config.baseUrl + "avatars/" + rowData.avatar
              : AvatarImage
          }
        />
      ),
    },
    { title: "名前", field: "name" },
    { title: "性別", field: "gender" },
    { title: "生年月日", field: "birthday" },
    { title: "メール", field: "email" },
    {
      title: "ステータス",
      field: "status",
      render: (rowData) => {
        let checked;
        if (rowData.status === "active") {
          checked = true;
        } else {
          checked = false;
        }
        return (
          <Switch
            checked={checked}
            onChange={(e) => handleChangeStatus(rowData)}
            inputProps={{ "aria-label": "controlled" }}
            color="secondary"
          />
        );
      },
    },
  ];

  const handleChangeStatus = (rowData) => {
    if (rowData.status === "active") {
      userService.updateUserStatus(rowData.id, "deactive");
      userService.getUsers().then((res) => setUsers(res.data.users));
    } else {
      userService.updateUserStatus(rowData.id, "active");
      userService.getUsers().then((res) => setUsers(res.data.users));
    }
  };

  const clickOpenDeleteModal = (id) => {
    setUserId(id);
    setDeleteUserModal(true);
  }
  
  const clickOpenModifyPwModal = (id) => {
    setUserId(id);
    setModifyPwModal(true);
  }
  
  const clickSubmitDelete = () => {
    userService.deleteUser(userId).then(res => {
      if (res.data.message == 'success') {
        // console.log('Success to remove');
        userService.getUsers().then((res) => setUsers(res.data.users));
      }
    }).catch((err)=>{
      console.log(err);
    });

    setDeleteUserModal(false);
  }

  const clickModifyPw = () => {
    userService.updateUserPasswd(userId, newPw).then(res => {
      if (res.data.message == 'success') {
        alert('Update password successfully !')
      }
    }).catch((err)=>{
      console.log(err);
    });

    setModifyPwModal(false);
  }

  function detailProfile(id) {
    let base_url = "/personalProfile/"
    let total_url = base_url + id + "/he"
    window.open(total_url, "_blank")
  }

  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  
  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
        >
          <MaterialTable
            title="ユーザー管理"
            icons={tableIcons}
            columns={columns}
            data={users}
            actions={[
              {
                icon: tableIcons.Lock,
                tooltip: "パスワード変更",
                onClick: (event, rowData) => {
                  clickOpenModifyPwModal(rowData.id);
                },
              },
              {
                icon: tableIcons.Person,
                tooltip: "プロフィール表示",
                onClick: (event, rowData) => {
                  detailProfile(rowData.id);
                },
              },
              {
                icon: tableIcons.Delete,
                tooltip: "削除",
                onClick: (event, rowData) => {
                  clickOpenDeleteModal(rowData.id);
                },
              }
            ]}
            onRowClick={((evt, selectedRow) => {
                setSelectedRow(selectedRow.tableData.id)
              }
            )}
            options={{
              actionsColumnIndex: -1,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
              })
            }}
            localization={{
              pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}',
                  labelRowsSelect: '人'
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected',
                  searchPlaceholder: '検索'
              },
              header: {
                  actions: '操作'
              },
              body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                      filterTooltip: 'Filter'
                  }
              }
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteUserModal}
        TransitionComponent={Transition}
        onClose={() => setDeleteUserModal(false)}
      >
        <DialogContent>
        <DialogTitle
          disableTypography
          style={{ textAlign: "center" }}
        >
          <h4><b>本当に削除しますか？</b></h4>
        </DialogTitle>
        <DialogActions
          className={
            classes.modalFooter + " " + classes.modalFooterCenter
          }
        >
          <Button
            onClick={() => setDeleteUserModal(false)}
            color="transparent"
            style={{ backgroundColor: "lightgrey", color: "black" }}
          >
            キャンセル
          </Button>
          <Button
            onClick={() => clickSubmitDelete()}
            style={{ backgroundColor: "#e12e3e" }}
          >
            削除する
          </Button>
        </DialogActions> 
        </DialogContent>
      </Dialog>
      <Dialog 
        open={modifyPwModal} 
        TransitionComponent={Transition}
        onClose={() => setModifyPwModal(false)} 
        >
          <DialogContent>
            <DialogTitle
              disableTypography
              style={{ textAlign: "center" }}
            >
              <h4><b>ユーザーのパスワードを変更しますか？</b></h4>
            </DialogTitle>
            <TextField
              autoFocus
              margin="dense"
              label="新しいパスワード"
              type="text"
              fullWidth
              value={newPw}
              onChange={(event) => {
                setNewPw(event.target.value);
              }}
            />
            <span style={{ fontSize: "10px" }}>
              ※8〜16文字の半角英数字で入力してください.
            </span>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
          }
          >
            <Button
              onClick={() => setModifyPwModal(false)}
              color="transparent" 
              style={{ backgroundColor: "lightgrey", color: "black" }}>
              キャンセル
            </Button>
            <Button
              onClick={() => clickModifyPw()}
              style={{ backgroundColor: "#e12e3e" }}>
              変更
            </Button>
          </DialogActions>
      </Dialog>      
    </Fragment>
  );
}

AdminUser.propTypes = {
  theme: PropTypes.object,
  onClose: PropTypes.func,
  openTermsDialog: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  classes: PropTypes.object,
};

export default AdminUser;
