import React, { Fragment, useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box, Typography, isWidthUp, withWidth, InputLabel, Paper, Card, CardContent, Avatar, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import styles from "./../components/style/mainStyle";
import TopHeader from "views/components/Home/TopHeader.js";
import Footer from "views/components/Home/Footer";
// import Profile from "views/components/SettingProfile";
import SettingOption from "views/components/Home/SettingOption";
import AvatarImage from "assets/img/faces/noname.jpg";

import * as authAction from "redux/actions/auth";
import * as storageService from "./../../services/storageService";
import * as authService from "./../../services/authService";
import * as userService from "services/userService";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import config from "config/config";
import Button from "components/CustomButtons/Button.js";


const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Setting = (props) => {
    const { isAuthenticated } = props;
    const classes = useStyles();
    const history = useHistory();
    const [select, setSelect] = useState({
      // profile: "rose",
      setting: "white",
      feedback: "white",
    });
    const [likedNum, setLikedNum] = useState(0);

    const [introduction, setIntroduction] = useState("");
    const handleIntroduction = (event) => {
      setIntroduction(event.target.value)
    }
    const getIntroduction = (val) =>{
      setIntroduction(val);
    }
    const handleChange = (name, color)  => {
      var newSelect = select;
      for (let key in select) { 
        if (key != name) {
          newSelect = { ...newSelect, [key]: "white" };
        } else {
          newSelect = { ...newSelect, [key]: color };
        }
      }
      setSelect(newSelect);
    }
    const [showItem, setShowItem] = useState();
    useEffect(() => {
      userService.getMyLiked().then(({ status, data }) => {
        if (status === 200 && data.message === "success") {
          setLikedNum(data.count);
        } else {
        }
      });

      let tabId = parseInt(props.match.params.id);
      switch (tabId) {
        // case 1:
        //   handleChange("profile", "rose")
        //   break;
        case 2:
          handleChange("setting", "rose")
          break;
        case 3:
          handleChange("feedback", "rose")
          break;
      }
      setShowItem(tabId)
    }, [props.match.params.id]);
    
    const [logoutModal, setLogoutModal] = useState(false);

    const handleLogOut = () => {
      authService.logOut().then((result) => {});
  
      setLogoutModal(false);
      storageService.removeStorage("token");
      storageService.removeStorage("user");
      storageService.removeStorage("skip");
      history.push("/");
      props.logout();
    };
    
    const profileImg = props.user.user_avatar
      ? config.baseUrl + "avatars/" + props.user.user_avatar
      : AvatarImage;
  
    return (
      <div className={classes.root}>
        <Fragment>
        <TopHeader id="top" />
        <Box style={{margin:20}}>
          <Grid container justifyContent="center" style={{marginTop: 100}}>
            <Grid item xs={12} sm={10} lg={8}>
              <Paper>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={3}>
                  <Card>
                      <CardContent style={{justifyContent: 'center', display: "flex"}}>
                        <Avatar
                          variant="rounded"
                          src={profileImg}
                          alt="avatar"
                          style={{marginTop: "4px", width: "90%", height: "90%"}}
                        />
                      </CardContent>
                      <CardContent style={{justifyContent: 'center', display: "flex", paddingTop: 0}}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {props.user.name}
                        </Typography>
                      </CardContent>
                      <CardContent style={{justifyContent: 'center', paddingTop: 0}}>
                        <Typography variant="subtitle1">受け取ったNice： {likedNum}人</Typography>
                      </CardContent>
                      <CardContent>
                        <Typography>
                          自己紹介
                        </Typography>
                        <TextareaAutosize
                          minRows={3}
                          aria-label="minRows height"
                          placeholder="minRows 4 rows"
                          style={{width: "100%"}}
                          value={introduction}
                          onChange={handleIntroduction}
                        />
                      </CardContent>
                      { showItem != 1 &&
                        <>
                          <CardContent style={{justifyContent: 'center', display: "flex"}}>
                            <Button
                              color={select.setting}
                              fullWidth
                              onClick={() => handleChange("setting", "rose")}
                              >
                              設 定
                            </Button>
                          </CardContent>
                          <CardContent style={{justifyContent: 'center', display: "flex"}}>
                            <Button
                              color={select.feedback}
                              fullWidth
                              onClick={() => handleChange("feedback", "rose")}
                              >
                              フィードバック・要望
                            </Button>
                          </CardContent>
                          <CardContent style={{justifyContent: 'center', display: "flex"}}>
                            <Button
                              color="white"
                              fullWidth 
                              onClick={() => setLogoutModal(true)}
                            >
                              ログアウト
                            </Button>
                          </CardContent>
                        </>
                      }
                  </Card>
                </Grid>
                <Grid item xs={8} style={{ marginTop: 30 }}>
                  {/* {select.profile == "rose" &&
                    <Profile userId={props.user.id} introduction={introduction} getIntroduction={(e) => getIntroduction(e)}/>
                  } */}
                  {select.setting == "rose" &&
                    <SettingOption />
                  }
                  {select.feedback == "rose" &&
                    <Typography paragraph>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
                      facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
                      gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
                      donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                      adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
                      Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
                      imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
                      arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
                      donec massa sapien faucibus et molestie ac.
                    </Typography>
                  }
                </Grid>
              </Grid>
                {/* <SettingOption /> */}
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Footer />
        </Fragment>
        <Dialog
          open={logoutModal}
          TransitionComponent={Transition}
          onClose={() => setLogoutModal(false)}
        >
          <DialogContent>
            <DialogTitle disableTypography style={{ textAlign: "center" }}>
              <h4>
                <b>ログアウトしますか？</b>
              </h4>
            </DialogTitle>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => setLogoutModal(false)}
                style={{ backgroundColor: "lightgrey", color: "black" }}
              >
                キャンセル
              </Button>
              <Button
                onClick={() => handleLogOut()}
                style={{ backgroundColor: "#e12e3e" }}
              >
                ログアウト
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {
  logout: authAction.Logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Setting));